// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    width: 100%;
    min-height: 100%;
    flex-wrap: wrap;
    background-color: $white;
    @include respond-above(lg) {
        overflow-x: hidden;
        overflow-y: auto;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        overflow: visible;
    }

}

.main-container {
    width: 100%;
}

.search-content-block{
    .search-items{
        padding-bottom: 5px;
        padding-top: 20px;
        border-top: 1px solid $border-color;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include respond-below(md) {
            flex-wrap: wrap;
        }    
        .search-img{
            width: 200px;
            padding: 0 15px;
            @include respond-below(md) {
                width: 100%;
                max-width: 200px;
                margin: 0 auto 20px;
            }
        }
        .search-content{
            width: calc(100% - 200px);
            @include respond-below(md) {
                width: 100%;
            }
            .h6{
                text-decoration: none;
                display: inline-block;
                margin-bottom: 15px;
                &:hover{
                    color: $brand-secondary;
                }
            }
            p{
                margin-bottom: 15px;
            }
        }
    }
}
.pagination-wrap{
    margin: 50px 0;
    .nav-pagination{
        .page-numbers{
            line-height: 1;
            padding: 0 15px;
            display: inline-block;
            vertical-align: middle;
            font-size: $font-size-base;
            text-decoration: none;
            font-weight: 500;
            &.current, &:hover{
                color: $brand-secondary;
            }
             
        }

    }
}
span.wpcf7-not-valid-tip{
    font-size: $font-size-small - 2;
}
div.wpcf7-validation-errors, div.wpcf7-acceptance-missing{
    font-size: $font-size-small - 2;
    text-align: center;
}
div.wpcf7 .ajax-loader{
    margin-top: 20px;
    @include respond-below(lg) { 
        margin-top: 15px;
    }
    @include respond-below(md) {
        margin-top: 15px;
    }
}

.flag-contact-wrap {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 300px;
    width: 100%;
} 

.language-dropdown{
    position: absolute;
    top: -20px;
    left: -20px;
    background: #002d5a;
    max-width: 153px;
    width: 100%;
    height: 63px;
    @media (min-width:1024px) and (max-width:1199px) {
        height: 60px;  
    }
    @include respond-below(lg) { 
        height: 77px;
        left: 0;
    }

    z-index: 9;
    &:before{
        content: "";
        position: absolute;
        right:22px;
        top:25px;
        background: url(../images/down-arrow.png);
        width:18px;
        height: 11px;
        background-size: 100% 100%;
        pointer-events: none;
        @include respond-below(lg) { 
            top:33px;
        }
    }
    &.open{
        &:before{
            transform: rotate(-180deg);
        }
    }
    .country-name {
        color: #fff;
        font-size: 14px;
    }
    img{
        width: 30px;
        border: 1px solid #fff;
        padding: 4px;
        height: 20px;
        object-fit: cover;
        margin-left: 10px;
    }
    .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -15px;
    }

    ul , li{
        margin: 0;
        padding: 0;
    }
    li{
        list-style: none;
        background: #484848;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            width: 100%;
            text-align: center;
            text-decoration: none;
            padding: 15px 0;
        }
    }
    .select-list{
        .selected {
            display: none;
        }
    }
    .dropdown-button{
        padding-top: 13%;
        padding-bottom: 13%;
        @include respond-below(lg) { 
            padding-top: 18%;
        }
    }
}

.social-icons {
    margin-top:30px;
    margin-bottom: 30px;
    img{
        height: 25px;
    }
    a {
        margin-right: 30px;
        &:hover{
            opacity: 0.8;
        }
    }
}

.footer-flag {
	display: flex;
    align-items: center;
    img{
        border: 1px solid #fff;
        width: 47px;
        padding: 6px;
    }
    span{
        margin-left: 10px;
    }
}

.product-listing-section{
    h3 {
        font-size: 22px;
        color: #000000;
        max-width: 1420px;
        margin: 0 auto 40px;
        @media ( max-width: 1500px ) {
            padding: 0 20px;
        }
    }
}

.footer-language-dropdown{
    .language-dropdown{
        position: relative;
        top: auto;
        left: auto;
        height: auto;
    } 
    .select-list{
        width:100% !important;
    }
}