// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

/* responsive nav for small devices */
.mobilenav {
    display: none;
    @include respond-below(xl) {
        display: block;	
    }
    .mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: darken($brand-primary, 10%);
        padding:0 15px;
        .nav-logo {
            width: 150px;
            display: inline-block;	
            @include respond-below(md) {
                width: 100px;
            }
        }
    }
    @at-root .hamburger {
        display: flex;
        height: 30px;
        width: 35px;
        padding: 0;
        margin: 0;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: none;
        @include respond-below(lg) {
           display: block;
         }

        &:not(.close) {
            position: absolute;
            top: 37px;
            right: 20px;
            z-index: 100;
            @include respond-below(md) {
                top: 23px;
            }
        }

        &.close {
            .wrap {
                position: relative;
                display: block;
                width: 35px;
                height: 30px;
                .line{
                    background-color: $brand-secondary;
                }
            }

            .line {
                position: absolute;
                top: calc(50% - 2px);
                width: 100%;
                margin: 0;
                transform: rotate(45deg);
                background-color: $white;

                &:last-child {
                    transform: rotate(-45deg);
                }
            }
        }

        &:hover,
        &:focus {
            background-color: transparent;
            outline: none;
            cursor: pointer;
        }

        .line {
            display: block;
            width: 100%;
            margin-bottom: 7px;
            height: 4px;
            background-color: $brand-secondary;
            &:last-child{
                margin-bottom: 0;
            }
        }

        .wrap {
            display: block;
        }

    }

    .menu-state {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: 999;
        width: 100%;
        max-width: 100%;
        background-color: $brand-primary;
        transition: all 0.3s ease-in-out;
    }

    &.is-open {
        .menu-state {
            left: 0;
        }
    }



    ul {
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;

        ul {
            display: none;
        }

        li {
            position: relative;
            padding: 0;
            margin: 0;
            list-style: none;

            .caret {
                position: absolute;
                top: 5px;
                right: 10px;
                display: block;
                width: 36px;
                height: 36px;
                cursor: pointer;

                &:not(.trigger-caret) {
                    pointer-events: none;
                }

                &:after,
                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                    margin: auto;
                    width: 18px;
                    height: 2px;
                    background: $white;
                }

                &:after {
                    transform: rotate(-90deg);
                    transition: all .2s ease;
                }
            }

            &.is-open {
                >.caret:after {
                    transform: rotate(0);
                }
            }

            a {
                z-index: 2;
                display: block;
                padding: 10px 15px;
                cursor: pointer;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                font-size: $font-size-base;
                text-decoration: none;
                color: $white;
            }

            &.has-sub {
                >a {
                    padding-right: 45px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    line-height: 1.3;
                }
            }

            li {
                a {
                    padding-left: 30px;
                    font-size: $font-size-small;
                    background-color: lighten($brand-primary,5%);
                }

                li {
                    a {
                        padding-left: 50px;
                        font-size: $font-size-small - 1;
                        background-color: lighten($brand-primary,10%);
                    }
                    li{
                        a{
                            padding-left: 60px;
                            font-size: $font-size-small - 2;
                            background-color: lighten($brand-primary,15%);
                        }
                    }
                }
            }
        }
    }

    .nav-backdrop {
        position: fixed;
        z-index: -1;
        height: 100%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
        background: rgba(0, 0, 0, 0.6);
    }

    &.is-open {
        .nav-backdrop {
            opacity: 1;
            z-index: 999;
            visibility: visible;
        }
    }

    .menu-outer {
        max-height: calc(100vh - 80px);
        padding-bottom: 60px;
        overflow-y: auto;
        .mobile-menu-icon{
            .mobile-menu-cat-img{
                width: 50px;
                height: 50px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                margin-right: 10px;

                img{
                    max-height: 100%;
                    max-width: 100%;
                    margin-right: 15px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 auto;
                }

                &.no-img img {
                    filter: grayscale(100%) brightness(100);
                }
            }
            > .caret{
                top: 15px;
            }
        }

    }
}
.menu-active{
    position: fixed;
    overflow: hidden;

}