// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.hero-section {
    position: relative;
    padding-top: 145px;

    @include respond-below(xl) {
        padding-top: 110px;
    }

    @include respond-below(md) {
        padding-top: 70px;
    }


    .hero-bg {
        position: absolute;
        left: 0;
        top: 0;
        @include square(100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }


    .hero-content {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 0;
            background-image: url(../images/transparent-skew.png);
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
            @include square(400px);

            @include respond-below(xxl) {
                @include square(350px);
            }

            @include respond-below(xl) {
                @include square(270px);
            }
        }

        &:after {
            top: auto;
            bottom: 0;
        }

        .custome-arrows {
            margin: 0;
            position: absolute;
            display: inline-block;
            z-index: 9;
            transition: 0.4s all;

            @include respond-below(md) {
                bottom: 25px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0 !important;
                right: 0 !important;
            }
        }

        .hero-slider {
            z-index: 9;

            .hero-wrapper {
                position: relative;
                height: 605px;
                padding: 50px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                @include respond-below(xxl) {
                    height: 500px;
                }

                @include respond-below(xl) {
                    height: 400px;
                }

                @include respond-below(md) {
                    height: auto;
                    padding: 30px 0 70px;
                    flex-wrap: wrap;
                }

            }

            .hero-detail {
                width: 50%;
                padding-left: calc((100% - 1050px)/2);
                @include respond-below(xl) {
                    padding-left: 50px;
                }

                @include respond-below(lg) {
                    padding-left: 15px;
                }

                @include respond-below(md) {
                    width: 100%;
                    align-items: center;
                    margin-bottom: 30px;
                    padding: 0 15px;
                }

                .hero-detial-inner {
                    padding-bottom: 80px;
                    @include respond-below(md) {
                        padding-bottom: 0;
                        text-align: center;
                    }
                }

                h1, .h1 {
                    color: $white;
                    margin-bottom: 0;
                }

                p {
                    color: $white;
                    @include respond-below(lg) {
                        font-size: $font-size-small;
                    }
                }
            }

            .hero-image {
                width: 50%;
                order: 1;
                padding: 0 15px;

                @include respond-below(md) {
                    width: 100%;
                    padding: 0;
                    transform: none;
                    position: static;
                    padding: 15px;
                    order: 0;
                }

                img {
                    margin: 0 auto;
                    max-width: 75%;
                    width: 100%;

                    @include respond-below(md) {
                        max-width: 320px;
                    }
                    @include respond-below(sm) {
                        max-width: 250px;
                    }
                }
            }


        }
    }
}

.inner-banner {
    position: relative;
    border-bottom: 20px solid $brand-secondary;

    .inner-banner-img {
        height: 100%;
        @include background-img-common(cover, no-repeat, center);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.15;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, 0.5);
            content: "";
        }
    }

    .inner-hero-wrapper {
        padding: 130px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @include respond-below(xxl) {
            padding: 100px 0;
        }

        @include respond-below(xl) {
            padding: 60px 0;
        }

        @include respond-below(lg) {
            padding: 40px 0;
        }

        @include respond-below(md) {
            flex-wrap: wrap;
        }

        .inner-hero-img {
            width: 45%;
            padding: 0 80px;
            text-align: center;
            @include respond-below(xl) {
                padding: 0 40px;
            }

            @include respond-below(lg) {
                padding: 0 15px;
                width: 40%;
            }

            @include respond-below(md) {
                width: 100%;
                padding: 0;
                margin-bottom: 20px;
            }
            img{
                @include respond-below(md) {
                    max-width: 320px;
                }
                @include respond-below(sm) {
                    max-width: 250px;
                }
            }

        }

        .inner-hero-img-wrapper {
            width: 45%;
            padding: 0 80px;
            text-align: center;

            @include respond-below(lg) {
                width: 40%;
            }

            @include respond-below(md) {
                width: 100%;
            }

            .inner-hero-img {
                width: 100%;
                padding: 0;

                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .inner-hero-content {
            width: 55%;

            @include respond-below(lg) {
                width: 60%;
            }

            @include respond-below(md) {
                width: 100%;
            }

            .h4 {
                margin-bottom: 30px;
                color: $brand-primary;

                @include respond-below(xl) {
                    margin-bottom: 25px;
                }
            }

            p {
                margin-bottom: 30px;
                font-size: $font-size-small;

                @include respond-below(xl) {
                    margin-bottom: 25px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
            table {
                tr{
                    th,td{
                        white-space: pre-wrap;
                        font-size: $font-size-small - 1;
                        padding: 15px 5px;
                    }
                    th{
                    }
                    td{
                      
                    }
                }
            }
        }
    }
}
.banner {
    height: 35vh;
    max-height: 500px;
    min-height: 300px;
    overflow: hidden;
    position: relative;

    @include respond-below(md) {
        height: 20vh;
        min-height: 200px;
    }
    .banner-bg {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        &.dark-bg{
            &:before{
                background-color: rgba($light-banner,0.7);
            }
        }
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            @include square(100%);
            background-color: rgba($dark-blue,0.5);
            content: "";
        }
    }

    .banner-content {
        position: absolute;
        left: 0;
        top: 0;
        // padding-top: 146px;
        @include square(100%);
        // @include respond-below(xl) {
        //     padding-top: 106px;
        // }
        // @include respond-below(md) {
        //     padding-top: 71px;
        // }
        .banner-content-inner {
            position: relative;
            @include square(100%);
            display: flex;
            //justify-content: center;
            align-items: center;
            text-align: center;

            &:before,
            &:after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 0;
                background-image: url(../images/transparent-skew.png);
                background-repeat: no-repeat;
                background-size: contain;
                pointer-events: none;
                @include square(400px);

                @include respond-below(xxl) {
                    @include square(350px);
                }

                @include respond-below(xl) {
                    @include square(270px);
                }
            }

            &:after {
                top: auto;
                bottom: 0;
            }
            .container{
                position: relative;
                z-index: 9;
            }
        }

        h1 {
            color: $brand-secondary;
        }
        p{
            margin-bottom: 0;
            color: $light-blue-font;
            font-size: $font-size-base;
            @include respond-below(sm) {
                font-size: $font-size-small;
            }
            a{
                &:hover{
                    color: $brand-secondary;
                }
            }
        }
        .find-location{
            color: $white;
            text-decoration: none;
            font-size: $font-size-small;
            display: inline-block;
            margin-top: 90px;
            @include respond-below(xxl) {
                margin-top: 60px;
            }
            @include respond-below(xl) {
                margin-top: 40px;
            }
            @include respond-below(lg) {
                margin-top: 20px;
            }
            @include respond-below(sm) {
                font-size: $font-size-small - 2;
            }
            &:hover{
                color: $brand-secondary;
            }
            i{
                color: $pink;
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;
                font-size: $font-size-large + 4;
                line-height: 1;
                @include respond-below(sm) {
                    font-size: $font-size-large;
                }
            }
            img{
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;

            }
        }
    }
}



