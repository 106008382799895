

.product-category {
    padding: 20px 0;
    @include respond-below(lg) {
        padding: 20px 50px;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: stretch;
        @include respond-below(xl) {
            flex-wrap: wrap;
        }

        li {
            list-style: none;
            padding: 0 40px;
            margin: 0;
            border-right: 1px solid $gray-dark;
            @include respond-below(xxl) {
                padding: 0 30px;
            }

            @include respond-below(xl) {
                padding: 0 15px;
                width: 25%;
            }

            @include respond-below(lg) {
                padding: 0 7px;
                width: 33.33%;
            }

            @include respond-below(md) {
                margin: 10px 0;
                width: 50%;
            }
            @include respond-below(sm) {
                width: 100%;
            }

            &:last-child {
                border: 0;
            }

            a {
                display: block;
                text-align: center;
                text-decoration: none;
                font-size: $font-size-small - 1;
                font-weight: 700;
                color: $gray-font;
                white-space: nowrap;
                @include respond-below(xl) {
                    font-size: $font-size-small - 3;
                }        
                &:hover {
                    color: $brand-primary;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    height: 85px;
                }
            }
        }
    }
}
.product-category-slider{
    &:before{
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color:$white;
        content: "";
        z-index: 1;
    }
    .slick-arrow{
        background-color: transparent;
        font-size: 0;
        padding: 0;
        @include square(50px);
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        outline: none;
        &:before{
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translate(0,-50%);
            @include crp('f');
            font-size: $font-size-large + 2;
            color: $brand-primary;
        }
        &.slick-next {
            right: -50px;
        }
        &.slick-prev{
            left: -50px;
            &:before{
                @include crp('d');
            }
        }
    }
}
.page-info-wrapper{
    position: relative;    
    .page-info{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        box-shadow: 0 0 0px 1px rgba(0,0,0,0.0);
        background-color: $white;
        &.fixed{
            position: fixed;
            box-shadow: 0 0 0px 1px rgba(0,0,0,0.2);
        }
    }
}
.page-info {
    padding: 20px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.4s all;
    @include respond-below(xl) {
        padding: 20px 15px;
    }

    @include respond-below(lg) {
        flex-wrap: wrap;
    }
    &.detail-breadcum{
        justify-content: space-between;
        .download-pdf{
            width: auto;

            @include respond-below(sm) {
                width: 100%;
            }
        }
    }
    .breadcrumbs {
        width: 33.33%;
        padding-left: 25px;
        @include respond-below(lg) {
            width: 100%;
            margin: 10px 0;
            padding-left: 15px;
        }

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -15px;
            padding: 0;
            position: relative;
            padding-left: 35px;

            li {
                padding: 0 15px 0 25px;
                list-style: none;
                position: relative;

                @include respond-below(xxl) {
                    padding: 0 10px 0 20px;
                }

                @include respond-below(xl) {
                    padding: 0 7px 0 14px;
                }
                &:first-child{
                    padding-left: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    @include crp('f');
                    font-size: $font-size-base - 5;
                    color: $breadcumb-arrow;
                    line-height: 1;
                    @include respond-below(xl) {
                        left: -2px;
                    }
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }

                a {
                    text-decoration: none;
                    color: $text-color;
                    font-weight: 500;
                    font-size: $font-size-small;

                    @include respond-below(xl) {
                        font-size: $font-size-small - 1;
                    }
                    &:before{
                        line-height: 1;
                        font-size: $font-size-large;
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -4px;
                    }
                    &:hover {
                        color: $brand-primary;
                    }
                    &.active {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.product-links {
    width: 33.33%;
    @include respond-below(lg) {
        width: 100%;
        margin: 10px 0;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        @include respond-below(lg) {
            justify-content: flex-start;
            margin: 0 -8px;
        }
        @include respond-below(sm) {
            flex-wrap: wrap;
        }

        li {
            list-style: none;
            padding: 0 15px;

            @include respond-below(xxl) {
                padding: 0 10px;
            }

            @include respond-below(xl) {
                padding: 0 8px;
            }

            @include respond-below(sm) {
                display: block;
                width: 100%;
            }

            a {
                color: $brand-primary;
                text-decoration: none;
                font-weight: 500;
                font-family: $font-family-primary;
                font-size: $font-size-small;
                @include respond-below(xl) {
                    font-size: $font-size-small - 1;
                }
                &:hover{
                    color: $text-color;
                }
            }
        }
    }
}

.download-pdf {
    width: 33.33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    color: $text-color;
    font-size: $font-size-small - 2;
    background: #fce013;
    padding: 10px;
    @include respond-below(lg) {
        justify-content: flex-start;
        margin-top: 10px;
    }
    @include respond-below(lg) {
        width: 100%;
    }
    @include respond-below(sm) {
        margin: 10px 0;
    }

    .pdf-icon {
        margin-right: 15px;

        @include respond-below(xl) {
            margin-right: 10px;
        }

        img {
            height: 100%;
            max-height: 48px;
            max-width: 48px;
            width: auto;
        }
    }

    &:hover {
        text-decoration: none;
        color: $brand-primary;
    }
}

.page-info-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-below(lg) {
        width: 100%;
        margin: 10px 0;
    }

    @include respond-below(sm) {
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: -10px;
    }

    .btn {
        margin-left: 50px;
        padding: 20px 25px 20px 40px;
        line-height: 1;
        font-size: $font-size-base - 2;
        font-weight: 700;
        @include respond-below(xxl) {
            margin-left: 40px;
        }

        @include respond-below(xl) {
            margin-left: 25px;
            padding: 20px 20px 20px 30px;
        }
        @include respond-below(sm) {
            margin-left: 0;
            padding: 15px;
            font-size: $font-size-small - 2;
        }

        i {
            margin-left: 25px;
            display: inline-block;
            vertical-align: middle;

            @include respond-below(xl) {
                margin-left: 15px;
            }
        }

        &:hover {
            color: $text-color;
        }
    }
}