// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.woff2') format('woff2'),
      url('../fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
      url('../fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
      url('../fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Ultra';
  src: url('../fonts/Gotham-Ultra.woff2') format('woff2'),
      url('../fonts/Gotham-Ultra.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
      url('../fonts/Gotham-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueBold.woff2') format('woff2'),
      url('../fonts/HelveticaNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueLight.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueMedium.woff2') format('woff2'),
      url('../fonts/HelveticaNeueMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueRegular.woff2') format('woff2'),
      url('../fonts/HelveticaNeueRegular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}




@font-face {
    font-family: "crp";
    src:url("../fonts/crp.eot");
    src:url("../fonts/crp.eot?#iefix") format("embedded-opentype"),
      url("../fonts/crp.woff") format("woff"),
      url("../fonts/crp.ttf") format("truetype"),
      url("../fonts/crp.svg#crp") format("svg");
    font-weight: normal;
    font-style: normal;
  
  }
  
  [data-icon]:before {
    font-family: "crp" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak-as: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    font-family: "crp" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak-as: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.icon-arrow-right-long:before {
  content: "\61";
}
.icon-bellows:before {
  content: "\62";
}
.icon-helmate:before {
  content: "\63";
}
.icon-left-arrow:before {
  content: "\64";
}
.icon-outline:before {
  content: "\65";
}
.icon-right-arrow:before {
  content: "\66";
}
.icon-safty-shield:before {
  content: "\67";
}
.icon-sampling:before {
  content: "\68";
}
.icon-search:before {
  content: "\69";
}
.icon-surface:before {
  content: "\6a";
}
.icon-web-page-home:before {
  content: "\6b";
}
.icon-location-pin:before {
  content: "\6c";
}