// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular ( Open Sans ) font family
$font-family-base:  'Gotham', sans-serif !default;
$font-family-primary: 'HelveticaNeue', sans-serif !default;

/// Code (Courier) font family
// $font-family-code:  'Courier New','Arial', sans-serif !default;

/// body contect text size
$font-size-base:    18px !default;
$font-size-small:   $font-size-base - 2 !default;
$font-size-large:   $font-size-base + 2 !default;

// Colors
$brand-primary :   #004D9B !default;
$brand-secondary : #FFD400 !default;

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$gray:   #F5F5F5 !default;
$light-blue: #E7EFF6;
$light-blue-text : #98B1C9;
$button-border: #80AEDC;
$gray-font:#717171;
$gray-dark: #DEDEDE;
$breadcumb-arrow: #A5A5A5;
$dark-blue: #11233C;
$light-banner: #003684;
$pink: #FF7676;
$border-color: #E2E2E2;
$menu-link:#222222;
$light-blue-font: #C4DEF7;
// Body
//
// Settings for the `<body>` element.
$body-bg:       $white !default;
$body-color:    $black !default;

// text color
$text-color:   $black !default ;

// Heading font family
$font-size-h1:  52px !default;
$font-size-h2:  42px !default;
$font-size-h3:  36px !default;
$font-size-h4:  30px !default;
$font-size-h5:  22px !default;
$font-size-h6:  20px !default;

$headings-margin-bottom: 30px !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   700 !default;
$headings-line-height:   1.1 !default;
$headings-color:         $brand-primary !default;

// Forms
$label-color:           $text-color;
$label-font-weight:     500;
$label-font-family:     $font-family-base;
$label-font-size:       $font-size-small;

$input-bg:                  $white;
$input-font-family:         $font-family-base;
$input-font-size:           $font-size-small;
$input-font-weight:         400;
$input-color:               $text-color;
$input-color-placeholder:   #B4B4B4;
$input-height-base:         50px;
$input-height-large:        65px;
$input-height-small:        50px;
$input-radius:              0;
$input-border-color:        #D8D8D8;
$input-border-color-focus:  $brand-primary;
$textarea-height-base:      150px;

/// Container's maximum width
$container-width:   1450px !default;

// media query breakpoints.
$breakpoints: (
  'sm': 641px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1200px,
  'xxl': 1500px,
);

// spacing : padding and margin
$spaceamounts: (0, 20, 30, 40, 50);

