.map-distribution{
    position: relative;
    padding: 90px 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include respond-below(xxl) {
        padding: 50px 0;
    }

    &.has-image {
        .map-content {
            width: 34%;
            @include respond-below(lg) {
                width: 50%;
            }
            @include respond-below(md) {
                width: 100%;
            }   
        }
    }

    .map-content{
        
        .map-inner-content{
            padding: 0 15px;
        }
        h3{
            font-size: $font-size-h3 - 6;
            color: $brand-primary;
            margin-bottom: 40px;
            @include respond-below(xxl) {
                margin-bottom: 30px;
            }
            @include respond-below(xl) {
                margin-bottom: 20px;
                font-size: $font-size-h3 - 12;
            }
            @include respond-below(md) {
                font-size: $font-size-h3 - 14;
            }
        }
        p{
            @include respond-below(lg) {
                font-size: $font-size-small;
            }
        }
        ul{
            margin: 80px 0 0 0;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            @include respond-below(xxl) {
                margin-top: 30px;
            }
            li{
                list-style: none;
                width: 50%;
                padding: 10px 0;
                margin: 0;
                line-height: 1;
                @include respond-below(xl) {
                    padding: 7px 0;
                }
                a{
                    display: inline-block;
                    text-decoration: none;
                    line-height: 1.2;
                    font-size: $font-size-large;
                    font-weight: 700;
                    @include respond-below(xl) {
                        font-size: $font-size-base - 2;
                    }
                    &:hover{
                        color: $brand-secondary;
                    }
                }
            }
        }

        .distributor-list {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;

            .distributor {
                width: 50%;
                @include respond-above(lg) {
                    margin-bottom: 80px;
                    
                    &:nth-of-type(2n) {
                        padding-left: 40px;
                    }

                    &:nth-of-type(2n+1) {
                        padding-right: 40px;
                    }
                }

                @include respond-below(lg) {
                    margin-bottom: 50px;
                
                    &:nth-of-type(2n) {
                        padding-left: 20px;
                    }

                    &:nth-of-type(2n+1) {
                        padding-right: 20px;
                    }
                }
                @include respond-below(md) {
                    width: 100%;
                }
            }
        }
    }
    .map-image{
        order: 1;
        width: 60%;
        @include respond-below(lg) {
            width: 50%;
        }
        @include respond-below(md) {
            width: 100%;
            margin-bottom: 20px;
            order: 0;

        }
        img{
            width: 100%;
        }
    }
}

.blue-container {
    background-color: $brand-primary;

    * {
        color: $white !important;
    }
}