// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

/*--- header ---*/
.main-header {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    @include respond-below(xl) {
        .container {
            padding: 0;
        }
    }

    @include respond-below(lg) {
        background-color: $brand-primary;
    }

    &.innner-header {
        background-color: $brand-primary;
        position: relative;
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .left-header {
        width: 200px;

        @include respond-below(xl) {
            width: 150px;
        }

        @include respond-below(md) {
            width: 100px;
        }

        .logo {
            display: inline-block;
            vertical-align: top;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .right-header {
        width: calc(100% - 200px);

        @include respond-below(xl) {
            width: calc(100% - 150px);
        }

        @include respond-below(lg) {
            display: none;
        }

        .top-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 7px 20px;
            background: #002d5a;
            background: -moz-linear-gradient(left, #002d5a 1%, #004385 100%);
            background: -webkit-linear-gradient(left, #002d5a 1%, #004385 100%);
            background: linear-gradient(to right, #002d5a 1%, #004385 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002d5a', endColorstr='#004385', GradientType=1);
            position: relative;

            @include respond-below(xxl) {
                padding: 5px 10px;
            }

            @include respond-below(xl) {
                padding: 3px 10px;
            }

            .side-color {
                position: absolute;
                left: 100%;
                background-color: #004385;
                content: "";
                top: 0;
                width: 100vw;
                height: 100%;
            }

            .search-block {
                max-width: 700px;
                width: 100%;
                padding-left: 10px;

                @include respond-below(xxl) {
                    padding-left: 0;
                    max-width: 500px;
                }

                form {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    input[type="submit"] {
                        @include square(50px);
                        background-image: url('../images/search.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 20px 20px;
                        background-color: transparent;
                    }

                    input[type="text"] {
                        background-color: transparent;
                        border: 0;
                        color: $white;
                        @include placeholder-color($white, 0.9, normal);
                        font-style: normal;
                        padding: 0 5px;
                        font-size: $font-size-small;
                    }
                }
            }

            .header-right-logo {
                margin-right: 30px;
                img {
                    max-width: 250px; 
                }
            }

            .contact-link {
                display: flex;
                flex: 1 0 auto;
                justify-content: flex-end;
                a {
                    color: $white;
                    outline: none;
                    text-decoration: none;
                    border: 0;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: $font-size-small - 3;

                    @include respond-below(lg) {}

                    &:hover {
                        color: $brand-secondary;
                    }

                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 30px;
                        color: $brand-secondary;
                        line-height: 1;

                        @include respond-below(xxl) {
                            margin-left: 20px;
                        }

                        @include respond-below(lg) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .flag-img {
            margin-left: 40px;
            img {
                max-width: 40px;
                display: inline-block;
                width: 100%;
            }
        }
        .navigation {
            padding: 0 20px 0 40px;
            background: #004D9B;

            @include respond-below(xxl) {
                padding: 0 10px 0 25px;
            }

            @include respond-below(xl) {
                padding: 0 5px 0 15px;
            }

            .side-color {
                position: absolute;
                left: 100%;
                background-color: $brand-primary;
                content: "";
                top: 0;
                width: 100vw;
                height: 100%;
            }

            nav {
                width: 100%;
                height: auto;

                ul {
                    padding: 0;
                    margin: 0 -5px;
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include respond-below(xxl) {
                        // margin: 0 -10px;
                    }

                    @include respond-below(xl) {
                        margin: 0;
                    }

                    @include respond-below(lg) {
                        //  margin: 0 -6px;
                    }

                    li {
                        padding: 0 5px;
                        margin: 0;
                        list-style: none;
                        display: block;

                        @include respond-below(xxl) {
                            // padding: 0 10px;
                        }

                        @include respond-below(lg) {
                            // padding: 0 6px;
                        }

                        &:hover, &.current_page_item {

                            >a {
                                color: $brand-secondary;
                            }
                        }
                        &.megamenu{
                            > a{
                                position: relative;
                                &:before{
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: 0 auto;
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0 17.5px 14px 17.5px;
                                    border-color: transparent transparent $white transparent;
                                    opacity: 0;
                                    visibility: hidden;
                                    pointer-events: none;
                                    @include transition(0.4s);
                                    @include respond-below(lg) {
                                        border-width: 0 12.5px 11px 12.5px;
                                    }
                                }
                            }
                            &:hover, &.active {
                                > a{
                                    &:before{
                                        opacity: 1;
                                        visibility: visible;
                                        pointer-events: all;
                                    }
                                }
                                .mega-menu-wrapper{
                                    opacity: 1;
                                    visibility: visible;
                                    pointer-events: all;
                                }
                            }
                        }
                        &.sub-menu {
                            position: relative;

                            &:hover {
                                >ul {
                                    opacity: 1;
                                    visibility: visible;
                                    pointer-events: all;
                                    top: 100%;
                                }
                            }

                            ul {
                                margin: 0;
                                position: absolute;
                                left: 0;
                                top: 100px;
                                flex-direction: column;
                                opacity: 0;
                                visibility: hidden;
                                pointer-events: none;
                                background-color: $brand-secondary;
                                min-width: 100%;
                                @include transition(0.4s);
                                z-index: 2;

                                li {
                                    padding: 0;
                                    display: block;
                                    width: 100%;

                                    &:hover {
                                        >a {
                                            color: $brand-secondary;
                                            background-color: $brand-primary;
                                        }

                                        ul {
                                            top: 0;
                                        }
                                    }


                                    a {
                                        color: $brand-primary;
                                        padding: 10px 15px;
                                        text-align: left;
                                        display: block;
                                        width: 100%;
                                        text-transform: capitalize;
                                    }

                                    ul {
                                        left: 100%;
                                        top: 30px;
                                    }

                                }
                            }
                        }

                        &:not(.sub-menu)>ul {
                            display: none;
                        }

                        .mega-menu-wrapper {
                            position: absolute;
                            left: 0;
                            top: 100%;
                            width: 100vw;
                            height: auto;
                            right: 0;
                            background: $white;
                            opacity: 0;
                            visibility: hidden;
                            pointer-events: none;
                            @include transition(0.4s);
                            z-index: 1;
                            &:before, &:after{
                                position: absolute;
                                right: 100%;
                                top: 0;
                                width: 100vw;
                                content: "";
                                background-color: $white;
                                height: 100%;
                            }
                            &:after{
                                right: auto;
                                left: 100%;
                            }
                            .menu-title {
                                >ul {
                                    display: flex;
                                    justify-content: center;
                                    align-items: stretch;
                                    flex-wrap: wrap;
                                    >li{
                                        padding: 0;
                                        width: 11.11%;
                                        &:last-child{
                                            > a{
                                                &:after{
                                                    display: none;
                                                }
                                            }
                                        }
                                        &:hover{
                                            > a{
                                                // box-shadow: 0 0 5px rgba($black,0.22);
                                            }
                                            .menu-wrapper{
                                                opacity: 1;
                                                visibility: visible;
                                                pointer-events: all;
                                                z-index: 1;
                                                ul{
                                                    pointer-events: all;
                                                }
                                            }
                                        }
                                        >a{
                                            padding: 40px 20px;
                                            //                                            box-shadow: 0 0 5px transparent;
                                            text-align: center;
                                            font-size: $font-size-base - 4;
                                            position: relative;
                                            display: block;
                                            @include respond-below(lg) {
                                                padding: 25px 15px;
                                            }

                                            &:after{
                                                content: "";
                                                position: absolute;
                                                right: 0;
                                                top: 50%;
                                                transform: translate(0,-50%);
                                                height: 70%;
                                                width: 1px;
                                                background-color: $border-color;
                                            }
                                        }
                                    }
                                    li {

                                        a {
                                            color: $menu-link;
                                            font-weight: 500;
                                            text-transform: capitalize;
                                            white-space: pre-wrap;
                                            .menu-cat-img{
                                                height: 85px;
                                                max-width: 85px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                margin: 0 auto;
                                                @include respond-below(lg) {
                                                    height: 60px;
                                                }
                                                img{
                                                    max-height: 100%;
                                                }
                                            }
                                            img {
                                                display: block;
                                                margin: 0 auto 15px;
                                            }
                                        }

                                        .menu-wrapper {
                                            width: 100%;
                                            background-color: $white;
                                            position: absolute;
                                            left: 0;
                                            top: 100%;
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: stretch;
                                            font-size: 0;
                                            padding: 40px 0;
                                            border-top: 1px solid $border-color;
                                            margin: 0 auto;
                                            opacity: 0;
                                            visibility: hidden;
                                            pointer-events: none;
                                            @include transition(0.4s);
                                            z-index: -1;
                                            @include respond-below(lg) {
                                                padding: 25px 0;
                                            }
                                            &:before, &:after{
                                                position: absolute;
                                                right: 100%;
                                                top: -1px;
                                                width: 100vw;
                                                content: "";
                                                background-color: $white;
                                                height: 100%;
                                                border-top: 1px solid $border-color;
                                            }
                                            &:after{
                                                right: auto;
                                                left: 100%;
                                            }
                                            .submenu-product-list{
                                                width: 33.33%;
                                                padding: 0 50px;
                                                display: inline-block;
                                                border-right: 1px solid $border-color;
                                                margin: 0;
                                                @include respond-below(lg) {
                                                    padding: 0 30px; 
                                                }
                                                &:last-child {
                                                    border: 0;
                                                }
                                                li {
                                                    width: 100%;

                                                    &:hover{
                                                        > a{
                                                            color: $brand-primary;
                                                        }
                                                    }
                                                    a {
                                                        font-size: $font-size-base - 3;
                                                        padding: 15px 0;
                                                        font-weight: 500;
                                                        @include respond-below(lg) {
                                                            font-size: $font-size-small - 1;
                                                            padding: 10px 0;
                                                        }

                                                    }

                                                    ul {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        padding: 0;
                                                        width: 100%;
                                                        position: static;
                                                        background-color: transparent;
                                                        li{
                                                            padding-left: 30px;
                                                            position: relative;

                                                            &:hover{
                                                                a{
                                                                    background-color: transparent;
                                                                }
                                                            }
                                                        }
                                                        a{
                                                            display: inline-block;
                                                            padding: 10px 0;
                                                            width: auto;
                                                            font-weight: 400;
                                                            &:before{
                                                                @include crp("f");
                                                                left: 0;
                                                                top: 10px;
                                                                position: absolute;
                                                                font-size: $font-size-small;
                                                                color: $border-color;
                                                                line-height: 1;
                                                                @include transition(0.4s);
                                                                @include respond-below(lg) {
                                                                    font-size: $font-size-small - 2;
                                                                }
                                                            }

                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }

                            }
                        }
                        a {
                            padding: 35px 0;
                            display: inline-block;
                            text-decoration: none;
                            line-height: 1;
                            color: $white;
                            font-weight: 700;
                            white-space: nowrap;
                            font-size: $font-size-small - 1;
                            text-transform: uppercase;
                            @include respond-below(xxl) {
                                font-size: $font-size-small - 3;
                            }
                            @include respond-below(xl) {
                                padding: 20px 0;
                                font-size: $font-size-small - 5;
                            }
                            a{
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }

}

.m-flag-img {
    display: none;
    @include respond-below(md) {
        display: block;
        position: absolute;
        right: 70px;
        top: 17px;
        width: 153px;
        max-width: 153px;
    }
}

