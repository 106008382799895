/**
* Basic typography style for copy text
*/
body {
    font-size: $font-size-base;
    font-family: $font-family-base;
    color: $text-color;
    line-height: 1.7;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: 400;

    @include respond-below(lg) {
        font-size: $font-size-small;
    }

}

// Heading style
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    @include respond-below(md) {
        margin-bottom: $headings-margin-bottom - 10;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
}

h1,
.h1 {
    font-size: $font-size-h1;
    @include respond-below(xxl) {
        font-size: $font-size-h1 - 8;
    }
    @include respond-below(xl) {
        font-size: $font-size-h1 - 10;
    }

    @include respond-below(lg) {
        font-size: $font-size-h1 - 20;
    }

    @include respond-below(md) {
        font-size: $font-size-h1 - 22;
    }

    @include respond-below(sm) {
        font-size: $font-size-h1 - 28;
    }
}

h2,
.h2 {
    font-size: $font-size-h2;

    @include respond-below(xl) {
        font-size: $font-size-h2 - 10;
    }

    @include respond-below(lg) {
        font-size: $font-size-h2 - 14;
    }

    @include respond-below(md) {
        font-size: $font-size-h2 - 18;
    }

    @include respond-below(sm) {
        font-size: $font-size-h2 - 20;
    }
}

h3,
.h3 {
    font-size: $font-size-h3;

    @include respond-below(xl) {
        font-size: $font-size-h3 - 10;
    }

    @include respond-below(md) {
        font-size: $font-size-h3 - 14;
    }

    @include respond-below(sm) {
        font-size: $font-size-h3 - 16;
    }

}

h4,
.h4 {
    font-size: $font-size-h4;

    @include respond-below(xl) {
        font-size: $font-size-h4 - 4;
    }

    @include respond-below(lg) {
        font-size: $font-size-h4 - 6;
    }

    @include respond-below(md) {
        font-size: $font-size-h4 - 8;
    }

    @include respond-below(sm) {
        font-size: $font-size-h4 - 10;
    }
}

h5,
.h5 {
    font-size: $font-size-h5;

    @include respond-below(xl) {
        font-size: $font-size-h5 - 2;
    }

    @include respond-below(lg) {
        font-size: $font-size-h5 - 4;
    }

    @include respond-below(sm) {
        font-size: $font-size-h5 - 8;
    }

}

h6,
.h6 {
    font-size: $font-size-h6;

    @include respond-below(xl) {
        font-size: $font-size-h6 - 2;
    }

    @include respond-below(lg) {
        font-size: $font-size-h6 - 4;
    }

    @include respond-below(sm) {
        font-size: $font-size-h6 - 7;
    }
}

p {
    margin-bottom: 30px;

    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: $brand-primary;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    padding-left: 20px;
    margin-bottom: 30px;

    ul {
        margin-bottom: 0;
    }

    li {
        padding-top: 6px;
        padding-bottom: 6px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

ol {
    padding-left: 20px;
    margin-bottom: 30px;

    ol {
        margin-bottom: 0;
    }

    li {
        padding-top: 6px;
        padding-bottom: 6px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

blockquote {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding: 20px 20px;
    text-align: left;
    line-height: 2;
    font-size: $font-size-large;
    font-style: italic;
    color: $white;
    background-color: $brand-primary;
    border-left: 10px solid $brand-secondary;

    @include respond-below(lg) {
        font-size: $font-size-base;
    }

    @include respond-below(md) {
        font-size: $font-size-small;
    }


    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
    }
}

hr {
    height: 0;
    margin: 10px auto 40px;
    border: 0 $black solid;
    border-top-width: 1px;
}

img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: top;
    border: none;
}

/* Link
------------------------------------------------------------------------------*/
a {
    color: $brand-primary;
    transition: background-color 0.3s ease 0s, color 0.3s ease 0s;

    &:hover,
    &:focus {
        color: $black;
        text-decoration: underline;
        outline: none;
    }
}

/* Tables
------------------------------------------------------------------------------*/
table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    background-color: $white;

    th {
        background-color: $brand-primary;
        color: $white;
        border-color: $brand-primary;

    }

    td,
    th {
        padding: 15px;
        font-size: $font-size-large;
        white-space: nowrap;
        font-weight: 500;
        vertical-align: middle;
        text-align: center;
        @include respond-below(lg) {
            font-size: $font-size-base;
        }
        @include respond-below(md) {
            font-size: $font-size-small;
        }
    }
    td{
        border: 1px solid $gray-dark;
    }
    tr {
        &:first-child {
            background-color: lighten($gray-dark, 5%);
        }
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 1px;
}

.table-bordered {
    width: 100%;
    max-width: 100%;
    text-align: left;
}

.custome-arrows {
    margin-left: -15px;

    .slick-arrow {
        display: inline-block;
        @include square(50px);
        border: 1px solid $button-border;
        text-align: center;
        color: $white;
        font-size: $font-size-base;
        text-decoration: none;
        line-height: 50px;
        margin-right: 15px;

        @include respond-below(md) {
            @include square(40px);
            line-height: 41px;
        }

        &:hover {
            background-color: $brand-secondary;
            color: $brand-primary;
        }
    }
}


.full-img {
    padding-top: 80px;
    //	margin: 50px 0;
    @include respond-below(lg) {
        padding-top: 50px;
        //		margin: 30px 0;
    }
    @include respond-below(md) {
        padding-top: 30px;
    }
    img {
        width: 100%;
        display: block;
    }
}
.banner + .cms-content-block,
.hero-section + .cms-content-block{
    padding-top: 100px;
    @include respond-below(xl) {
        padding-top: 80px;
    }
    @include respond-below(lg) {
        padding-top: 60px;
    }
    @include respond-below(md) {
        padding-top: 40px;
    }
}
.cms-content-block{
    padding-bottom: 30px;
    @include respond-below(md) {
        padding-bottom: 20px;
    }
    &.full-content{
        padding-bottom: 130px;
        @include respond-below(xl) {
            padding-bottom: 100px;
        }
        @include respond-below(lg) {
            padding-bottom: 80px;
        }
        @include respond-below(md) {
            padding-bottom: 60px;
        }
    }
}

