// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// media query mixin
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//== @include box-sizing();
@mixin box-sizing($box-sizing:border-box) {
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing: $box-sizing;
    box-sizing: $box-sizing;
}

//== @include clearfix();
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//== @include transition(0.4s);
@mixin transition($second:0.4s) {
    -webkit-transition: all $second ease 0s;
    transition: all $second ease 0s;
}

//== @include border-radius(100%);
@mixin border-radius($radius:100%) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

//== @include square(30px) same height-width
@mixin square($width:$height, $height:$width) {
    width: $width;
    height: $height;
}

//== @include background-opacity(#333, 0.5);
@mixin background-opacity($color, $opacity: 0.4) {
    background-color: $color;
    /* The Fallback */
    background-color: rgba($color, $opacity);
}

//== @include color-opacity(#333, 0.5);
@mixin color-opacity($color, $opacity: 0.4) {
    color: $color;
    /* The Fallback */
    color: rgba($color, $opacity);
}

//== @include background-img-common(cover, repeat, top left);
@mixin background-img-common($bg-size:cover, $bg-repeat:no-repeat, $bg-pos:center center) {
    background-size: $bg-size;
    background-repeat: $bg-repeat;
    background-position: $bg-pos;
}

//== @include flex-center(flex, center, center);
@mixin flex-center($flex:flex, $justify-content:center, $align-item:center) {
    display: $flex;
    justify-content: $justify-content;
    align-items: $align-item;
}

//== @include all-pos-zero;
@mixin all-pos-zero {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

//== @include placeholder-color (#ffffff,0.5,italic);
@mixin placeholder-color($color, $opacity, $font-style:italic) {
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $color;
        opacity: $opacity !important;
        font-style: $font-style;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: $opacity !important;
        font-style: $font-style;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
        opacity: $opacity !important;
        font-style: $font-style;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $color;
        opacity: $opacity !important;
        font-style: $font-style;
    }
}

//== @include rotate(45deg);
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

//== @include scale(1.1);
@mixin scale($x) {
    -webkit-transform: scale($x);
    -ms-transform: scale($x);
    -o-transform: scale($x);
    transform: scale($x);
}

//== @include box-shadow(inset 0 3px 5px #000);
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

//== @include box-none-shadow();
@mixin box-none-shadow() {
    -webkit-box-shadow: none; // iOS <4.3 & Android <4.1
    box-shadow: none;
}

//== @include text-overflow();
@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//== @include crp("c");
@mixin crp($content) {
    font-family: "crp" , sans-serif !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    content: $content;
}