.contact-section{
    padding: 80px 0 150px;
    position: relative;
    @include respond-below(xl) {
        padding: 50px 0 100px;
    }
    @include respond-below(md) {
        padding: 40px 0;
    }
    &:before{
        position: absolute;
        left: 0;
        top: 0;
        @include square(100%);
        content: "";
        background-color: rgba($white,0.93);
    }
    .contact-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        @include respond-below(md) {
            flex-wrap: wrap;
        }
        .contact-info{
            $info-margin: 40px;
            width: calc(40% - #{$info-margin});
            background-color: $white;
            padding: 40px 50px;
            margin-right: $info-margin;
            border-top: 10px solid $brand-secondary;
            @include box-shadow(0 5px 10px rgba($black,0.16));
            @include respond-below(xl) {
                padding: 25px 30px;
            }
            @include respond-below(lg) {
                width: calc(35% - #{$info-margin});
                padding: 20px;
            }
            @include respond-below(lg) {
                // padding-right: 0px;
            }
            @include respond-below(md) {
                width: 100%;
                padding: 0 15px;
                margin-bottom: 40px;
                margin-right: 0;
            }
            address{
                font-style: normal;
                margin-bottom: 50px;
                font-weight: 500;
                @include respond-below(xl) {
                    margin-bottom: 30px;
                    font-size: $font-size-small;
                }
                @include respond-below(lg) {
                    font-size: $font-size-small - 1;
                }

            }
            .contact-detail{
                ul{
                    padding: 0;
                    margin: 0 0 50px 0;
                    @include respond-below(xl) {
                        margin-bottom: 30px;
                    }
                    li{
                        padding: 0;
                        list-style: none;
                        color: $text-color;
                        margin-bottom: 20px;
                        font-weight: 500;
                        @include respond-below(xl) {
                            margin-bottom: 15px;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        a{
                            margin-left: 5px;
                            color: inherit;
                            text-decoration: none;
                            font-weight: inherit;
                            @include respond-below(xl) {
                                font-size: $font-size-small;
                            }
                            @include respond-below(lg) {
                                font-size: $font-size-small - 1;
                            }
                            &:hover{
                                color: $brand-primary;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .registration-detail{
                p{
                    color: $text-color;
                    margin-bottom: 0;
                    font-weight: 500;
                    @include respond-below(xl) {
                        font-size: $font-size-small;
                    }
                    @include respond-below(lg) {
                        font-size: $font-size-small - 1;
                    }
                }
            }
            .extra-text {
                margin-top: 20px;
            }
        }
        .contact-form{
            width: 60%;
            background-color: $white;
            padding: 40px 50px;
            border-top: 10px solid $brand-secondary;
            @include box-shadow(0 5px 10px rgba($black,0.16));
            @include respond-below(xl) {
                padding: 25px 30px;
            }
            @include respond-below(lg) {
                width: 63%;
                padding: 20px;
            }
            @include respond-below(md) {
                width: 100%;
            }
            input[type="submit"]{
                padding: 20px 70px;
                background-image: url('../images/white-arrow-long.svg');
                background-size: 18px 15px;
                background-repeat: no-repeat;
                background-position: 90% 50%;
                @include respond-below(xl) {
                    padding: 15px 60px;
                }
            }
        }
    }

}
.inner-address-block {
    label{
        font-weight: 400;
    }
}