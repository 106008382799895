// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

/*--- footer ---*/
.main-footer {
	width: 100%;
	height: auto;
	background: $brand-primary;
	line-height: normal;

	.footer-wrapper {

		// display: flex;
		// justify-content: space-between;
		// align-items: flex-start;
		// flex-wrap: wrap;
		.footer-block {
			// width: 33.33%
			padding: 50px 15px;

			@include respond-below(lg) {
				padding: 25px 15px;
			}

			@include respond-below(md) {
				padding: 0 15px;
				margin-bottom: 25px;
			}

			&.large {
				@include respond-between(md, lg) {
					order: 2;
				}
			}

			&:last-child {
				@include respond-below(md) {
					margin-bottom: 0;
				}
			}

			.footer-logo {
				margin-top: -50px;
				display: block;
				margin-bottom: 30px;

				@include respond-below(xl) {
					width: 160px;
					margin-bottom: 20px;
				}

				@include respond-below(lg) {
					margin-top: -25px;
				}

				@include respond-below(md) {
					margin-top: 0;
				}
			}

			address {
				font-style: normal;
				color: $white;
				margin-bottom: 30px;
				font-size: $font-size-base;
				line-height: 1.5;

				@include respond-below(xxl) {
					font-size: $font-size-small;
				}
				@include respond-below(md) {
					margin-bottom: 15px;
				}
			}

			* {
				color: $white;
			}

			p {
				font-size: $font-size-base;
				line-height: 1.5;
				margin-bottom: 10px;

				@include respond-below(xxl) {
					font-size: $font-size-small;
				}
				@include respond-below(lg) {
					font-size: $font-size-small - 2;
				}
				@include respond-below(md) {
					margin-bottom: 5px;
				}
			}

			ul {
				width: 49%;
				display: inline-block;
				vertical-align: top;
				margin: 0;
				padding: 0;

				@include respond-below(sm) {
					width: 100%;
					margin-bottom: 6px;
				}

				li {
					list-style: none;

					a {
						text-decoration: none;
						font-size: $font-size-base;
						line-height: 1.4;

						@include respond-below(xxl) {
							font-size: $font-size-small;
						}

						&:hover {
							color: $brand-secondary;
						}
					}
				}
			}

			.h6 {
				font-size: $font-size-h6 - 2;
				@include respond-below(md) {
					margin-bottom: 10px;
					font-size: $font-size-h6 - 4;
				}

			}

			.news-block {
				margin-bottom: 25px;
				padding-bottom: 25px;
				position: relative;
				display: block;
				text-decoration: none;
				@include respond-below(md) {
					margin-bottom: 15px;
					padding-bottom: 15px;
				}
				&:hover{
					p{
						color: $brand-secondary;
					}
				}
				&:last-child {
					&:before {
						display: none;
					}
				}

				&:before {
					position: absolute;
					left: 0;
					bottom: 0;
					height: 1px;
					width: 90%;
					background-color: $light-blue-text;
					content: "";
				}

				p {
					font-weight: 700;
					font-size: $font-size-base;
					@include transition(0.4s);
					@include respond-below(xl) {
						font-size: $font-size-small;
					}
				}

				.date {
					color: $light-blue-text;
					font-style: italic;
					@include respond-below(xl) {
						font-size: $font-size-small;
					}
					@include respond-below(md) {
						font-size: $font-size-small - 2;
					}
				}
			}
		}
	}
}