/* Home product section redesign */

.inner-banner .inner-hero-wrapper {
    align-items: flex-start;
}

.product-listing-section .product-listing-wrapper.row {
    width: 100%;
    max-width: 1450px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: stretch;
}

.product-listing-section {
    position: relative;
    padding: 30px 0px;
    background-color: #fff;
}

.product-listing-section:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/wp-content/themes/crp/assets/images/home-background.png');
    z-index: 0;
}

.product-listing-section .product-block {
    height: 500px;
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 40px;
    transition: ease all 0.3s;
    margin-bottom: 15px;

    @include respond-below(xl) {
        padding: 30px;
    }
}

.product-listing-section .product-block:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(0, 105, 212, 0.3) 0%, rgba(0, 105, 212, 0) 100%);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    transition: ease all 0.3s;
    z-index: -1;
}

.product-listing-section .product-block:hover {
    text-decoration: none;
}

.product-listing-section .product-block:hover:before {
    opacity: 1;
}

.product-listing-section .product-block:hover h5,
.product-listing-section .product-block:hover p {
    color: #004D9B
}

.product-listing-section .product-block:hover p {
    border: solid 2px #004D9B;
}

.product-listing-section .product-block:hover .product-title-button p:after {
    background-image: url('/wp-content/themes/crp/assets/images/arrow-blue-dark.png')
}

.product-listing-section .product-block p {
    display: none;
}

.product-listing-section .product-block h5 {
    margin-bottom: 0px;
    color: #0069D4;
    transition: ease all 0.3s;
}

.product-listing-section .product-block .product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 365px;
    padding-bottom: 20px;
}

.product-listing-section .product-block .product-img img {
    width: 70%;
    height: 100%;
    object-fit: contain;
    transition: ease all 0.3s;
    font-family: 'object-fit: contain;';
}

.product-listing-section .product-block:hover img {
    transform: scale(1.1);
}

.product-title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-listing-section .product-title-button p {
    display: block;
    color: #0069D4;
    padding: 15px;
    padding-right: 55px;
    border: solid 2px#0069D4;
    font-weight: 700;
    position: relative;
    transition: ease all 0.3s;
}

.product-listing-section .product-title-button p:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 15px;
    top: calc(50% - 7.5px);
    right: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/wp-content/themes/crp/assets/images/arrow-blue.png');
    transition: ease all 0.3s;
}

@media (min-width: 1000px) {
    .product-listing-section .product-block {
        width: calc(33% - 5px);
    }

    .product-listing-section .product-block h5 {
        max-width: 50%;
    }
}

.product-listing-section .product-block.contact {
    display: none;
}

.product-listing-section .product-container:last-child .product-block.contact:last-child {
    display: flex;
}

.product-listing-section .product-block.contact {
    justify-content: center;
}

.product-listing-section .product-block.contact p {
    display: block;
    border: none;
    color: #000;
    font-size: 18px;
    margin-bottom: 15px;
}

.product-listing-section .product-block.contact:hover p {
    border: none;
}

.product-listing-section .product-block.contact h2 {
    font-size: 25px;
    margin-bottom: 15px;
    color: #0069D4;
}

.product-listing-section .product-block.contact a {
    color: #000;
    margin-bottom: 15px;
    font-size: 18px;
    text-decoration: underline;
    transition: ease all 0.3s;
    padding-left: 40px;
    position: relative;
}

.product-listing-section .product-block.contact a:before,
.product-listing-section .product-block.contact p.location:before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 5px;
    left: 0px;
    transition: ease all 0.3s;
}

.product-listing-section .product-block.contact p.location {
    padding-left: 40px;
    position: relative;
}

.product-listing-section .product-block.contact a.phone:before {
    background-image: url('/wp-content/themes/crp/assets/images/phone-black.png');
}

.product-listing-section .product-block.contact a.email:before {
    background-image: url('/wp-content/themes/crp/assets/images/email-black.png');
}

.product-listing-section .product-block.contact p.location:before {
    background-image: url('/wp-content/themes/crp/assets/images/location-black.png');
}

.product-listing-section .product-block.contact a:hover {
    color: #004D9B;
}

.product-listing-section .product-block.contact a.phone:hover:before {
    background-image: url('/wp-content/themes/crp/assets/images/phone-blue.png');
}

.product-listing-section .product-block.contact a.email:hover:before {
    background-image: url('/wp-content/themes/crp/assets/images/email-blue.png');
}

/* Header redesign */

.main-header {
    background-color: #004d9b;
}

.main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title>ul li .menu-wrapper {
    display: none;
}

.main-header .right-header .navigation nav ul li a {
    position: relative;
}

.main-header .right-header .navigation nav ul li a:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 50px;
    background-color: #8BA0B5;
    opacity: 0.5;
    top: calc(50% - 25px);
    right: -20px;

    @include respond-below(xxl) {
        right: calc(((100vw / 16.2 - 74.5px) + 12px) * -1);
    }

    @include respond-below(xl) {
        right: calc(((100vw / 17.2 - 59.5px) + 15px) * -1);
    }
}

.main-header .right-header .navigation nav ul li.megamenu:last-child>a:after {
    display: none;
}

.featured-section .featured-outer {
    padding-left: 0px;
}

.banner.home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/wp-content/themes/crp/assets/images/background-home.png');
    height: auto;
    background-attachment: fixed;
    min-height: initial;
    max-height: initial;
}

.banner.home.product-page .banner-content {
    padding-top: 0px;
}

.banner.home h2,
.banner.home p {
    color: #fff;
    text-align: left;
}

.banner.home p {
    opacity: 0.85;
}

.banner.home .banner-content {
    position: static;
    overflow: hidden;
}

.banner.home .banner-content .banner-content-inner:before,
.banner.home .banner-content .banner-content-inner:after {
    display: none;
}

.banner.home .banner-content-inner {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    max-width: 1420px;
    margin: 0 auto;
}

.banner.home .banner-content-inner.text {
    width: 100%;
}

.banner.home .banner-content-inner .text hr {
    display: none;
}

.banner.home .banner-content-inner .image {
    // display: none;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@include respond-below(xxl) {
    .banner.home {
        .banner-content-inner {
            padding: 30px 20px;
        }
    }
}

@include respond-below(lg) {
    .banner.home {
        .banner-content-inner {
            .text {
                order: 2;
            }

            .image {
                order: 1;
                height: 130px;
                margin-bottom: 20px;
            }
        }
    }
}

@include respond-above(xxl) {
    .banner.home {
        .banner-content-inner {
            padding: 40px 0px;
        }
    }
}

@media (min-width: 1000px) {
    .banner.home .banner-content-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        position: relative;
    }

    .banner.home .banner-content-inner .text {
        width: 50%;
    }

    .banner.home .banner-content-inner .text h2,
    .banner.home .banner-content-inner .text h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .banner.home .banner-content-inner .text p {
        width: 70%;
    }

    .banner.home .banner-content-inner .image {
        display: block;
        width: 500px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 40px;
    }

    .banner.home .banner-content-inner .text hr {
        height: 2px;
        background-color: #fff;
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
        display: block;
    }
}

.banner.home .banner-content-inner .image.product-page {
    top: 0px;
}

/* Product page redesign */

.breadcrumb-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;

    @include respond-below(md) {
        flex-direction: column;
    }
}

.page-info .breadcrumbs {
    width: 100%;
}

.page-info .breadcrumbs ul {
    width: 100%;
}

.page-info-right {
    width: 100%;
    justify-content: flex-end;
}

.page-info {
    border-bottom: solid 1px #ececec;
}

// .mega-menu-wrapper .container {
//     border: solid 1px #ececec;
//     border-top: none;
// }

.mega-menu-wrapper:before,
.mega-menu-wrapper:after {
    display: none;
}

.single-products {
    .product-detail-section {
        h1 {
            text-align: center;
        }
    }
}


.product-detail-section h1,
.product-detail-section p,
.product-detail-section ul {
    text-align: left;
    font-size: 17px;
    margin-bottom: 20px;
}

.product-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.product-images img {
    width: 45%;
    height: auto;
    margin-bottom: 20px;
    // Fix IE height: auto
    min-height: 1px;
}

.product-table {
    overflow-x: scroll;
    margin-bottom: 20px;
}

.file-links {
    flex-shrink: 0;
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;

    .download-pdf {
        width: auto;
        margin-left: 10px;
        background: #fce013;
        padding: 10px;
    }

    @include respond-below(md) {
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;

        .download-pdf {
            margin-left: 0;
        }
    }
}

table td,
table th {
    font-size: 15px;
    font-weight: 400;
}

.product-information {
    z-index: 1;
}

@media (min-width: 1000px) {
    .product-detail-section .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .product-detail-section .product-information {
        width: 55%;
    }

    .product-detail-section .product-images {
        width: 35%;
        flex-direction: column;
    }

    .product-detail-section .product-images img {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .product-table {
        overflow-x: hidden;
    }

    .product-table table {
        width: 99%;
    }
}

/* Header slider */

.owl-dots {
    width: 90vw;
    max-width: 1420px;
    margin: 0 auto;
    margin-bottom: 20px;
    z-index: 999;
}

.owl-dots .owl-dot {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 360px;
    background-color: transparent;
    border: solid 2px #AAAAAA;
    padding: 0px;
    transition: ease all 0.3s;
    opacity: 0.5;
}

.owl-dots .owl-dot:hover {
    opacity: 1;
}

.owl-dots .owl-dot.active {
    background-color: #AAAAAA;
}

.owl-dots .owl-dot:last-child {
    margin-right: 0px;
}

.owl-carousel .owl-item {
    opacity: 0 !important;

    &.active {
        opacity: 1 !important;
    }
}

.menu-cat-img img {
    width: 100%;
}

.banner.home .banner-content-inner .text {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.banner.home .banner-content-inner .text a {
    margin-top: 15px;
    color: #FFF;
    transition: ease all 0.3s;
    display: flex;
    text-decoration: none;
    border: solid 2px;
    padding: 10px 15px;
    width: auto;
    font-size: 16px;
}

.banner.home .banner-content-inner .text a.download-pdf {
    width: auto;
    color: black !important;
}

.banner.home .banner-content-inner .text a:hover {
    color: #FFD400;
}

.inner-banner .inner-hero-wrapper {
    flex-direction: row-reverse;
}

.inner-banner-img {
    display: none;
}

// JULY 2020 //
ul {
    li {
        font-size: 16px;
    }
}

.inner-banner {
    .inner-hero-wrapper {
        padding: 45px 0px;
    }
}