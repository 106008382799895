@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Light.woff2") format("woff2"), url("../fonts/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Medium.woff2") format("woff2"), url("../fonts/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Bold.woff2") format("woff2"), url("../fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Ultra';
  src: url("../fonts/Gotham-Ultra.woff2") format("woff2"), url("../fonts/Gotham-Ultra.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Thin.woff2") format("woff2"), url("../fonts/Gotham-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/HelveticaNeueBold.woff2") format("woff2"), url("../fonts/HelveticaNeueBold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/HelveticaNeueLight.woff2") format("woff2"), url("../fonts/HelveticaNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/HelveticaNeueMedium.woff2") format("woff2"), url("../fonts/HelveticaNeueMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/HelveticaNeueRegular.woff2") format("woff2"), url("../fonts/HelveticaNeueRegular.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "crp";
  src: url("../fonts/crp.eot");
  src: url("../fonts/crp.eot?#iefix") format("embedded-opentype"), url("../fonts/crp.woff") format("woff"), url("../fonts/crp.ttf") format("truetype"), url("../fonts/crp.svg#crp") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "crp" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak-as: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "crp" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak-as: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-right-long:before {
  content: "\61"; }

.icon-bellows:before {
  content: "\62"; }

.icon-helmate:before {
  content: "\63"; }

.icon-left-arrow:before {
  content: "\64"; }

.icon-outline:before {
  content: "\65"; }

.icon-right-arrow:before {
  content: "\66"; }

.icon-safty-shield:before {
  content: "\67"; }

.icon-sampling:before {
  content: "\68"; }

.icon-search:before {
  content: "\69"; }

.icon-surface:before {
  content: "\6a"; }

.icon-web-page-home:before {
  content: "\6b"; }

.icon-location-pin:before {
  content: "\6c"; }

/* Reset css
   ========================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
a,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%; }

* {
  box-sizing: border-box; }

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  box-sizing: content-box;
  overflow: visible; }

/* Text-level semantics
   ========================================================================== */
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active,
a:hover {
  outline-width: 0; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
audio,
video {
  display: inline-block; }

/**
 * Main content containers
 */
.container {
  width: 100%;
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid {
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 1930px; }

/* row and column */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .row.no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .row.no-gutters > [class*="cell-"] {
      padding-right: 0;
      padding-left: 0; }

[class*="cell-"] {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%; }

/* align item */
.d-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .d-flex-inline {
    display: inline-flex; }
  .d-flex img {
    align-self: center; }

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center; }

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end; }

.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

/* column structure */
.cell-1 {
  width: 8.33333%; }

.cell-2 {
  width: 16.66667%; }

.cell-3 {
  width: 25%; }

.cell-4 {
  width: 33.33333%; }

.cell-5 {
  width: 41.66667%; }

.cell-6 {
  width: 50%; }

.cell-7 {
  width: 58.33333%; }

.cell-8 {
  width: 66.66667%; }

.cell-9 {
  width: 75%; }

.cell-10 {
  width: 83.33333%; }

.cell-11 {
  width: 91.66667%; }

.cell-12 {
  width: 100%; }

.mt-0,
.m-0,
.my-0 {
  margin-top: 0px; }

.pt-0,
.p-0,
.py-0 {
  padding-top: 0px; }

.mb-0,
.m-0,
.my-0 {
  margin-bottom: 0px; }

.pb-0,
.p-0,
.py-0 {
  padding-bottom: 0px; }

.ml-0,
.m-0,
.mx-0 {
  margin-left: 0px; }

.pl-0,
.p-0,
.px-0 {
  padding-left: 0px; }

.mr-0,
.m-0,
.mx-0 {
  margin-right: 0px; }

.pr-0,
.p-0,
.px-0 {
  padding-right: 0px; }

.mt-20,
.m-20,
.my-20 {
  margin-top: 20px; }

.pt-20,
.p-20,
.py-20 {
  padding-top: 20px; }

.mb-20,
.m-20,
.my-20 {
  margin-bottom: 20px; }

.pb-20,
.p-20,
.py-20 {
  padding-bottom: 20px; }

.ml-20,
.m-20,
.mx-20 {
  margin-left: 20px; }

.pl-20,
.p-20,
.px-20 {
  padding-left: 20px; }

.mr-20,
.m-20,
.mx-20 {
  margin-right: 20px; }

.pr-20,
.p-20,
.px-20 {
  padding-right: 20px; }

.mt-30,
.m-30,
.my-30 {
  margin-top: 30px; }

.pt-30,
.p-30,
.py-30 {
  padding-top: 30px; }

.mb-30,
.m-30,
.my-30 {
  margin-bottom: 30px; }

.pb-30,
.p-30,
.py-30 {
  padding-bottom: 30px; }

.ml-30,
.m-30,
.mx-30 {
  margin-left: 30px; }

.pl-30,
.p-30,
.px-30 {
  padding-left: 30px; }

.mr-30,
.m-30,
.mx-30 {
  margin-right: 30px; }

.pr-30,
.p-30,
.px-30 {
  padding-right: 30px; }

.mt-40,
.m-40,
.my-40 {
  margin-top: 40px; }

.pt-40,
.p-40,
.py-40 {
  padding-top: 40px; }

.mb-40,
.m-40,
.my-40 {
  margin-bottom: 40px; }

.pb-40,
.p-40,
.py-40 {
  padding-bottom: 40px; }

.ml-40,
.m-40,
.mx-40 {
  margin-left: 40px; }

.pl-40,
.p-40,
.px-40 {
  padding-left: 40px; }

.mr-40,
.m-40,
.mx-40 {
  margin-right: 40px; }

.pr-40,
.p-40,
.px-40 {
  padding-right: 40px; }

.mt-50,
.m-50,
.my-50 {
  margin-top: 50px; }

.pt-50,
.p-50,
.py-50 {
  padding-top: 50px; }

.mb-50,
.m-50,
.my-50 {
  margin-bottom: 50px; }

.pb-50,
.p-50,
.py-50 {
  padding-bottom: 50px; }

.ml-50,
.m-50,
.mx-50 {
  margin-left: 50px; }

.pl-50,
.p-50,
.px-50 {
  padding-left: 50px; }

.mr-50,
.m-50,
.mx-50 {
  margin-right: 50px; }

.pr-50,
.p-50,
.px-50 {
  padding-right: 50px; }

.cell-none {
  display: none; }

.cell-block {
  display: block; }

@media (min-width: 641px) {
  .cell-sm-1 {
    width: 8.33333%; }
  .cell-sm-2 {
    width: 16.66667%; }
  .cell-sm-3 {
    width: 25%; }
  .cell-sm-4 {
    width: 33.33333%; }
  .cell-sm-5 {
    width: 41.66667%; }
  .cell-sm-6 {
    width: 50%; }
  .cell-sm-7 {
    width: 58.33333%; }
  .cell-sm-8 {
    width: 66.66667%; }
  .cell-sm-9 {
    width: 75%; }
  .cell-sm-10 {
    width: 83.33333%; }
  .cell-sm-11 {
    width: 91.66667%; }
  .cell-sm-12 {
    width: 100%; }
  .mt-sm-0,
  .m-0,
  .my-sm-0 {
    margin-top: 0px; }
  .pt-sm-0,
  .p-0,
  .py-sm-0 {
    padding-top: 0px; }
  .mb-sm-0,
  .m-0,
  .my-sm-0 {
    margin-bottom: 0px; }
  .pb-sm-0,
  .p-0,
  .py-sm-0 {
    padding-bottom: 0px; }
  .ml-sm-0,
  .m-0,
  .mx-sm-0 {
    margin-left: 0px; }
  .pl-sm-0,
  .p-0,
  .px-sm-0 {
    padding-left: 0px; }
  .mr-sm-0,
  .m-0,
  .mx-sm-0 {
    margin-right: 0px; }
  .pr-sm-0,
  .p-0,
  .px-sm-0 {
    padding-right: 0px; }
  .mt-sm-20,
  .m-20,
  .my-sm-20 {
    margin-top: 20px; }
  .pt-sm-20,
  .p-20,
  .py-sm-20 {
    padding-top: 20px; }
  .mb-sm-20,
  .m-20,
  .my-sm-20 {
    margin-bottom: 20px; }
  .pb-sm-20,
  .p-20,
  .py-sm-20 {
    padding-bottom: 20px; }
  .ml-sm-20,
  .m-20,
  .mx-sm-20 {
    margin-left: 20px; }
  .pl-sm-20,
  .p-20,
  .px-sm-20 {
    padding-left: 20px; }
  .mr-sm-20,
  .m-20,
  .mx-sm-20 {
    margin-right: 20px; }
  .pr-sm-20,
  .p-20,
  .px-sm-20 {
    padding-right: 20px; }
  .mt-sm-30,
  .m-30,
  .my-sm-30 {
    margin-top: 30px; }
  .pt-sm-30,
  .p-30,
  .py-sm-30 {
    padding-top: 30px; }
  .mb-sm-30,
  .m-30,
  .my-sm-30 {
    margin-bottom: 30px; }
  .pb-sm-30,
  .p-30,
  .py-sm-30 {
    padding-bottom: 30px; }
  .ml-sm-30,
  .m-30,
  .mx-sm-30 {
    margin-left: 30px; }
  .pl-sm-30,
  .p-30,
  .px-sm-30 {
    padding-left: 30px; }
  .mr-sm-30,
  .m-30,
  .mx-sm-30 {
    margin-right: 30px; }
  .pr-sm-30,
  .p-30,
  .px-sm-30 {
    padding-right: 30px; }
  .mt-sm-40,
  .m-40,
  .my-sm-40 {
    margin-top: 40px; }
  .pt-sm-40,
  .p-40,
  .py-sm-40 {
    padding-top: 40px; }
  .mb-sm-40,
  .m-40,
  .my-sm-40 {
    margin-bottom: 40px; }
  .pb-sm-40,
  .p-40,
  .py-sm-40 {
    padding-bottom: 40px; }
  .ml-sm-40,
  .m-40,
  .mx-sm-40 {
    margin-left: 40px; }
  .pl-sm-40,
  .p-40,
  .px-sm-40 {
    padding-left: 40px; }
  .mr-sm-40,
  .m-40,
  .mx-sm-40 {
    margin-right: 40px; }
  .pr-sm-40,
  .p-40,
  .px-sm-40 {
    padding-right: 40px; }
  .mt-sm-50,
  .m-50,
  .my-sm-50 {
    margin-top: 50px; }
  .pt-sm-50,
  .p-50,
  .py-sm-50 {
    padding-top: 50px; }
  .mb-sm-50,
  .m-50,
  .my-sm-50 {
    margin-bottom: 50px; }
  .pb-sm-50,
  .p-50,
  .py-sm-50 {
    padding-bottom: 50px; }
  .ml-sm-50,
  .m-50,
  .mx-sm-50 {
    margin-left: 50px; }
  .pl-sm-50,
  .p-50,
  .px-sm-50 {
    padding-left: 50px; }
  .mr-sm-50,
  .m-50,
  .mx-sm-50 {
    margin-right: 50px; }
  .pr-sm-50,
  .p-50,
  .px-sm-50 {
    padding-right: 50px; }
  .cell-sm-none {
    display: none; }
  .cell-sm-block {
    display: block; } }

@media (min-width: 768px) {
  .cell-md-1 {
    width: 8.33333%; }
  .cell-md-2 {
    width: 16.66667%; }
  .cell-md-3 {
    width: 25%; }
  .cell-md-4 {
    width: 33.33333%; }
  .cell-md-5 {
    width: 41.66667%; }
  .cell-md-6 {
    width: 50%; }
  .cell-md-7 {
    width: 58.33333%; }
  .cell-md-8 {
    width: 66.66667%; }
  .cell-md-9 {
    width: 75%; }
  .cell-md-10 {
    width: 83.33333%; }
  .cell-md-11 {
    width: 91.66667%; }
  .cell-md-12 {
    width: 100%; }
  .mt-md-0,
  .m-0,
  .my-md-0 {
    margin-top: 0px; }
  .pt-md-0,
  .p-0,
  .py-md-0 {
    padding-top: 0px; }
  .mb-md-0,
  .m-0,
  .my-md-0 {
    margin-bottom: 0px; }
  .pb-md-0,
  .p-0,
  .py-md-0 {
    padding-bottom: 0px; }
  .ml-md-0,
  .m-0,
  .mx-md-0 {
    margin-left: 0px; }
  .pl-md-0,
  .p-0,
  .px-md-0 {
    padding-left: 0px; }
  .mr-md-0,
  .m-0,
  .mx-md-0 {
    margin-right: 0px; }
  .pr-md-0,
  .p-0,
  .px-md-0 {
    padding-right: 0px; }
  .mt-md-20,
  .m-20,
  .my-md-20 {
    margin-top: 20px; }
  .pt-md-20,
  .p-20,
  .py-md-20 {
    padding-top: 20px; }
  .mb-md-20,
  .m-20,
  .my-md-20 {
    margin-bottom: 20px; }
  .pb-md-20,
  .p-20,
  .py-md-20 {
    padding-bottom: 20px; }
  .ml-md-20,
  .m-20,
  .mx-md-20 {
    margin-left: 20px; }
  .pl-md-20,
  .p-20,
  .px-md-20 {
    padding-left: 20px; }
  .mr-md-20,
  .m-20,
  .mx-md-20 {
    margin-right: 20px; }
  .pr-md-20,
  .p-20,
  .px-md-20 {
    padding-right: 20px; }
  .mt-md-30,
  .m-30,
  .my-md-30 {
    margin-top: 30px; }
  .pt-md-30,
  .p-30,
  .py-md-30 {
    padding-top: 30px; }
  .mb-md-30,
  .m-30,
  .my-md-30 {
    margin-bottom: 30px; }
  .pb-md-30,
  .p-30,
  .py-md-30 {
    padding-bottom: 30px; }
  .ml-md-30,
  .m-30,
  .mx-md-30 {
    margin-left: 30px; }
  .pl-md-30,
  .p-30,
  .px-md-30 {
    padding-left: 30px; }
  .mr-md-30,
  .m-30,
  .mx-md-30 {
    margin-right: 30px; }
  .pr-md-30,
  .p-30,
  .px-md-30 {
    padding-right: 30px; }
  .mt-md-40,
  .m-40,
  .my-md-40 {
    margin-top: 40px; }
  .pt-md-40,
  .p-40,
  .py-md-40 {
    padding-top: 40px; }
  .mb-md-40,
  .m-40,
  .my-md-40 {
    margin-bottom: 40px; }
  .pb-md-40,
  .p-40,
  .py-md-40 {
    padding-bottom: 40px; }
  .ml-md-40,
  .m-40,
  .mx-md-40 {
    margin-left: 40px; }
  .pl-md-40,
  .p-40,
  .px-md-40 {
    padding-left: 40px; }
  .mr-md-40,
  .m-40,
  .mx-md-40 {
    margin-right: 40px; }
  .pr-md-40,
  .p-40,
  .px-md-40 {
    padding-right: 40px; }
  .mt-md-50,
  .m-50,
  .my-md-50 {
    margin-top: 50px; }
  .pt-md-50,
  .p-50,
  .py-md-50 {
    padding-top: 50px; }
  .mb-md-50,
  .m-50,
  .my-md-50 {
    margin-bottom: 50px; }
  .pb-md-50,
  .p-50,
  .py-md-50 {
    padding-bottom: 50px; }
  .ml-md-50,
  .m-50,
  .mx-md-50 {
    margin-left: 50px; }
  .pl-md-50,
  .p-50,
  .px-md-50 {
    padding-left: 50px; }
  .mr-md-50,
  .m-50,
  .mx-md-50 {
    margin-right: 50px; }
  .pr-md-50,
  .p-50,
  .px-md-50 {
    padding-right: 50px; }
  .cell-md-none {
    display: none; }
  .cell-md-block {
    display: block; } }

@media (min-width: 1024px) {
  .cell-lg-1 {
    width: 8.33333%; }
  .cell-lg-2 {
    width: 16.66667%; }
  .cell-lg-3 {
    width: 25%; }
  .cell-lg-4 {
    width: 33.33333%; }
  .cell-lg-5 {
    width: 41.66667%; }
  .cell-lg-6 {
    width: 50%; }
  .cell-lg-7 {
    width: 58.33333%; }
  .cell-lg-8 {
    width: 66.66667%; }
  .cell-lg-9 {
    width: 75%; }
  .cell-lg-10 {
    width: 83.33333%; }
  .cell-lg-11 {
    width: 91.66667%; }
  .cell-lg-12 {
    width: 100%; }
  .mt-lg-0,
  .m-0,
  .my-lg-0 {
    margin-top: 0px; }
  .pt-lg-0,
  .p-0,
  .py-lg-0 {
    padding-top: 0px; }
  .mb-lg-0,
  .m-0,
  .my-lg-0 {
    margin-bottom: 0px; }
  .pb-lg-0,
  .p-0,
  .py-lg-0 {
    padding-bottom: 0px; }
  .ml-lg-0,
  .m-0,
  .mx-lg-0 {
    margin-left: 0px; }
  .pl-lg-0,
  .p-0,
  .px-lg-0 {
    padding-left: 0px; }
  .mr-lg-0,
  .m-0,
  .mx-lg-0 {
    margin-right: 0px; }
  .pr-lg-0,
  .p-0,
  .px-lg-0 {
    padding-right: 0px; }
  .mt-lg-20,
  .m-20,
  .my-lg-20 {
    margin-top: 20px; }
  .pt-lg-20,
  .p-20,
  .py-lg-20 {
    padding-top: 20px; }
  .mb-lg-20,
  .m-20,
  .my-lg-20 {
    margin-bottom: 20px; }
  .pb-lg-20,
  .p-20,
  .py-lg-20 {
    padding-bottom: 20px; }
  .ml-lg-20,
  .m-20,
  .mx-lg-20 {
    margin-left: 20px; }
  .pl-lg-20,
  .p-20,
  .px-lg-20 {
    padding-left: 20px; }
  .mr-lg-20,
  .m-20,
  .mx-lg-20 {
    margin-right: 20px; }
  .pr-lg-20,
  .p-20,
  .px-lg-20 {
    padding-right: 20px; }
  .mt-lg-30,
  .m-30,
  .my-lg-30 {
    margin-top: 30px; }
  .pt-lg-30,
  .p-30,
  .py-lg-30 {
    padding-top: 30px; }
  .mb-lg-30,
  .m-30,
  .my-lg-30 {
    margin-bottom: 30px; }
  .pb-lg-30,
  .p-30,
  .py-lg-30 {
    padding-bottom: 30px; }
  .ml-lg-30,
  .m-30,
  .mx-lg-30 {
    margin-left: 30px; }
  .pl-lg-30,
  .p-30,
  .px-lg-30 {
    padding-left: 30px; }
  .mr-lg-30,
  .m-30,
  .mx-lg-30 {
    margin-right: 30px; }
  .pr-lg-30,
  .p-30,
  .px-lg-30 {
    padding-right: 30px; }
  .mt-lg-40,
  .m-40,
  .my-lg-40 {
    margin-top: 40px; }
  .pt-lg-40,
  .p-40,
  .py-lg-40 {
    padding-top: 40px; }
  .mb-lg-40,
  .m-40,
  .my-lg-40 {
    margin-bottom: 40px; }
  .pb-lg-40,
  .p-40,
  .py-lg-40 {
    padding-bottom: 40px; }
  .ml-lg-40,
  .m-40,
  .mx-lg-40 {
    margin-left: 40px; }
  .pl-lg-40,
  .p-40,
  .px-lg-40 {
    padding-left: 40px; }
  .mr-lg-40,
  .m-40,
  .mx-lg-40 {
    margin-right: 40px; }
  .pr-lg-40,
  .p-40,
  .px-lg-40 {
    padding-right: 40px; }
  .mt-lg-50,
  .m-50,
  .my-lg-50 {
    margin-top: 50px; }
  .pt-lg-50,
  .p-50,
  .py-lg-50 {
    padding-top: 50px; }
  .mb-lg-50,
  .m-50,
  .my-lg-50 {
    margin-bottom: 50px; }
  .pb-lg-50,
  .p-50,
  .py-lg-50 {
    padding-bottom: 50px; }
  .ml-lg-50,
  .m-50,
  .mx-lg-50 {
    margin-left: 50px; }
  .pl-lg-50,
  .p-50,
  .px-lg-50 {
    padding-left: 50px; }
  .mr-lg-50,
  .m-50,
  .mx-lg-50 {
    margin-right: 50px; }
  .pr-lg-50,
  .p-50,
  .px-lg-50 {
    padding-right: 50px; }
  .cell-lg-none {
    display: none; }
  .cell-lg-block {
    display: block; } }

@media (min-width: 1200px) {
  .cell-xl-1 {
    width: 8.33333%; }
  .cell-xl-2 {
    width: 16.66667%; }
  .cell-xl-3 {
    width: 25%; }
  .cell-xl-4 {
    width: 33.33333%; }
  .cell-xl-5 {
    width: 41.66667%; }
  .cell-xl-6 {
    width: 50%; }
  .cell-xl-7 {
    width: 58.33333%; }
  .cell-xl-8 {
    width: 66.66667%; }
  .cell-xl-9 {
    width: 75%; }
  .cell-xl-10 {
    width: 83.33333%; }
  .cell-xl-11 {
    width: 91.66667%; }
  .cell-xl-12 {
    width: 100%; }
  .mt-xl-0,
  .m-0,
  .my-xl-0 {
    margin-top: 0px; }
  .pt-xl-0,
  .p-0,
  .py-xl-0 {
    padding-top: 0px; }
  .mb-xl-0,
  .m-0,
  .my-xl-0 {
    margin-bottom: 0px; }
  .pb-xl-0,
  .p-0,
  .py-xl-0 {
    padding-bottom: 0px; }
  .ml-xl-0,
  .m-0,
  .mx-xl-0 {
    margin-left: 0px; }
  .pl-xl-0,
  .p-0,
  .px-xl-0 {
    padding-left: 0px; }
  .mr-xl-0,
  .m-0,
  .mx-xl-0 {
    margin-right: 0px; }
  .pr-xl-0,
  .p-0,
  .px-xl-0 {
    padding-right: 0px; }
  .mt-xl-20,
  .m-20,
  .my-xl-20 {
    margin-top: 20px; }
  .pt-xl-20,
  .p-20,
  .py-xl-20 {
    padding-top: 20px; }
  .mb-xl-20,
  .m-20,
  .my-xl-20 {
    margin-bottom: 20px; }
  .pb-xl-20,
  .p-20,
  .py-xl-20 {
    padding-bottom: 20px; }
  .ml-xl-20,
  .m-20,
  .mx-xl-20 {
    margin-left: 20px; }
  .pl-xl-20,
  .p-20,
  .px-xl-20 {
    padding-left: 20px; }
  .mr-xl-20,
  .m-20,
  .mx-xl-20 {
    margin-right: 20px; }
  .pr-xl-20,
  .p-20,
  .px-xl-20 {
    padding-right: 20px; }
  .mt-xl-30,
  .m-30,
  .my-xl-30 {
    margin-top: 30px; }
  .pt-xl-30,
  .p-30,
  .py-xl-30 {
    padding-top: 30px; }
  .mb-xl-30,
  .m-30,
  .my-xl-30 {
    margin-bottom: 30px; }
  .pb-xl-30,
  .p-30,
  .py-xl-30 {
    padding-bottom: 30px; }
  .ml-xl-30,
  .m-30,
  .mx-xl-30 {
    margin-left: 30px; }
  .pl-xl-30,
  .p-30,
  .px-xl-30 {
    padding-left: 30px; }
  .mr-xl-30,
  .m-30,
  .mx-xl-30 {
    margin-right: 30px; }
  .pr-xl-30,
  .p-30,
  .px-xl-30 {
    padding-right: 30px; }
  .mt-xl-40,
  .m-40,
  .my-xl-40 {
    margin-top: 40px; }
  .pt-xl-40,
  .p-40,
  .py-xl-40 {
    padding-top: 40px; }
  .mb-xl-40,
  .m-40,
  .my-xl-40 {
    margin-bottom: 40px; }
  .pb-xl-40,
  .p-40,
  .py-xl-40 {
    padding-bottom: 40px; }
  .ml-xl-40,
  .m-40,
  .mx-xl-40 {
    margin-left: 40px; }
  .pl-xl-40,
  .p-40,
  .px-xl-40 {
    padding-left: 40px; }
  .mr-xl-40,
  .m-40,
  .mx-xl-40 {
    margin-right: 40px; }
  .pr-xl-40,
  .p-40,
  .px-xl-40 {
    padding-right: 40px; }
  .mt-xl-50,
  .m-50,
  .my-xl-50 {
    margin-top: 50px; }
  .pt-xl-50,
  .p-50,
  .py-xl-50 {
    padding-top: 50px; }
  .mb-xl-50,
  .m-50,
  .my-xl-50 {
    margin-bottom: 50px; }
  .pb-xl-50,
  .p-50,
  .py-xl-50 {
    padding-bottom: 50px; }
  .ml-xl-50,
  .m-50,
  .mx-xl-50 {
    margin-left: 50px; }
  .pl-xl-50,
  .p-50,
  .px-xl-50 {
    padding-left: 50px; }
  .mr-xl-50,
  .m-50,
  .mx-xl-50 {
    margin-right: 50px; }
  .pr-xl-50,
  .p-50,
  .px-xl-50 {
    padding-right: 50px; }
  .cell-xl-none {
    display: none; }
  .cell-xl-block {
    display: block; } }

@media (min-width: 1500px) {
  .cell-xxl-1 {
    width: 8.33333%; }
  .cell-xxl-2 {
    width: 16.66667%; }
  .cell-xxl-3 {
    width: 25%; }
  .cell-xxl-4 {
    width: 33.33333%; }
  .cell-xxl-5 {
    width: 41.66667%; }
  .cell-xxl-6 {
    width: 50%; }
  .cell-xxl-7 {
    width: 58.33333%; }
  .cell-xxl-8 {
    width: 66.66667%; }
  .cell-xxl-9 {
    width: 75%; }
  .cell-xxl-10 {
    width: 83.33333%; }
  .cell-xxl-11 {
    width: 91.66667%; }
  .cell-xxl-12 {
    width: 100%; }
  .mt-xxl-0,
  .m-0,
  .my-xxl-0 {
    margin-top: 0px; }
  .pt-xxl-0,
  .p-0,
  .py-xxl-0 {
    padding-top: 0px; }
  .mb-xxl-0,
  .m-0,
  .my-xxl-0 {
    margin-bottom: 0px; }
  .pb-xxl-0,
  .p-0,
  .py-xxl-0 {
    padding-bottom: 0px; }
  .ml-xxl-0,
  .m-0,
  .mx-xxl-0 {
    margin-left: 0px; }
  .pl-xxl-0,
  .p-0,
  .px-xxl-0 {
    padding-left: 0px; }
  .mr-xxl-0,
  .m-0,
  .mx-xxl-0 {
    margin-right: 0px; }
  .pr-xxl-0,
  .p-0,
  .px-xxl-0 {
    padding-right: 0px; }
  .mt-xxl-20,
  .m-20,
  .my-xxl-20 {
    margin-top: 20px; }
  .pt-xxl-20,
  .p-20,
  .py-xxl-20 {
    padding-top: 20px; }
  .mb-xxl-20,
  .m-20,
  .my-xxl-20 {
    margin-bottom: 20px; }
  .pb-xxl-20,
  .p-20,
  .py-xxl-20 {
    padding-bottom: 20px; }
  .ml-xxl-20,
  .m-20,
  .mx-xxl-20 {
    margin-left: 20px; }
  .pl-xxl-20,
  .p-20,
  .px-xxl-20 {
    padding-left: 20px; }
  .mr-xxl-20,
  .m-20,
  .mx-xxl-20 {
    margin-right: 20px; }
  .pr-xxl-20,
  .p-20,
  .px-xxl-20 {
    padding-right: 20px; }
  .mt-xxl-30,
  .m-30,
  .my-xxl-30 {
    margin-top: 30px; }
  .pt-xxl-30,
  .p-30,
  .py-xxl-30 {
    padding-top: 30px; }
  .mb-xxl-30,
  .m-30,
  .my-xxl-30 {
    margin-bottom: 30px; }
  .pb-xxl-30,
  .p-30,
  .py-xxl-30 {
    padding-bottom: 30px; }
  .ml-xxl-30,
  .m-30,
  .mx-xxl-30 {
    margin-left: 30px; }
  .pl-xxl-30,
  .p-30,
  .px-xxl-30 {
    padding-left: 30px; }
  .mr-xxl-30,
  .m-30,
  .mx-xxl-30 {
    margin-right: 30px; }
  .pr-xxl-30,
  .p-30,
  .px-xxl-30 {
    padding-right: 30px; }
  .mt-xxl-40,
  .m-40,
  .my-xxl-40 {
    margin-top: 40px; }
  .pt-xxl-40,
  .p-40,
  .py-xxl-40 {
    padding-top: 40px; }
  .mb-xxl-40,
  .m-40,
  .my-xxl-40 {
    margin-bottom: 40px; }
  .pb-xxl-40,
  .p-40,
  .py-xxl-40 {
    padding-bottom: 40px; }
  .ml-xxl-40,
  .m-40,
  .mx-xxl-40 {
    margin-left: 40px; }
  .pl-xxl-40,
  .p-40,
  .px-xxl-40 {
    padding-left: 40px; }
  .mr-xxl-40,
  .m-40,
  .mx-xxl-40 {
    margin-right: 40px; }
  .pr-xxl-40,
  .p-40,
  .px-xxl-40 {
    padding-right: 40px; }
  .mt-xxl-50,
  .m-50,
  .my-xxl-50 {
    margin-top: 50px; }
  .pt-xxl-50,
  .p-50,
  .py-xxl-50 {
    padding-top: 50px; }
  .mb-xxl-50,
  .m-50,
  .my-xxl-50 {
    margin-bottom: 50px; }
  .pb-xxl-50,
  .p-50,
  .py-xxl-50 {
    padding-bottom: 50px; }
  .ml-xxl-50,
  .m-50,
  .mx-xxl-50 {
    margin-left: 50px; }
  .pl-xxl-50,
  .p-50,
  .px-xxl-50 {
    padding-left: 50px; }
  .mr-xxl-50,
  .m-50,
  .mx-xxl-50 {
    margin-right: 50px; }
  .pr-xxl-50,
  .p-50,
  .px-xxl-50 {
    padding-right: 50px; }
  .cell-xxl-none {
    display: none; }
  .cell-xxl-block {
    display: block; } }

/* text alignment */
.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-justify {
  text-align: justify; }

/* wordpress align class */
.alignnone {
  float: none;
  margin: 20px 0; }

.aligncenter {
  display: block;
  clear: both;
  margin: 20px auto; }

.alignleft {
  float: left;
  margin: 0 20px 20px 0; }

.alignright {
  float: right;
  margin: 0 0 20px 20px; }

input::-ms-clear {
  display: none; }

/**
* Basic typography style for copy text
*/
body {
  font-size: 18px;
  font-family: "Gotham", sans-serif;
  color: #000;
  line-height: 1.7;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400; }
  @media (max-width: 1023px) {
    body {
      font-size: 16px; } }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 30px;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: #004D9B; }
  @media (max-width: 767px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 20px; } }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  .h1 a,
  .h2 a,
  .h3 a,
  .h4 a,
  .h5 a,
  .h6 a {
    color: inherit;
    text-decoration: none; }

h1,
.h1 {
  font-size: 52px; }
  @media (max-width: 1499px) {
    h1,
    .h1 {
      font-size: 44px; } }
  @media (max-width: 1199px) {
    h1,
    .h1 {
      font-size: 42px; } }
  @media (max-width: 1023px) {
    h1,
    .h1 {
      font-size: 32px; } }
  @media (max-width: 767px) {
    h1,
    .h1 {
      font-size: 30px; } }
  @media (max-width: 640px) {
    h1,
    .h1 {
      font-size: 24px; } }

h2,
.h2 {
  font-size: 42px; }
  @media (max-width: 1199px) {
    h2,
    .h2 {
      font-size: 32px; } }
  @media (max-width: 1023px) {
    h2,
    .h2 {
      font-size: 28px; } }
  @media (max-width: 767px) {
    h2,
    .h2 {
      font-size: 24px; } }
  @media (max-width: 640px) {
    h2,
    .h2 {
      font-size: 22px; } }

h3,
.h3 {
  font-size: 36px; }
  @media (max-width: 1199px) {
    h3,
    .h3 {
      font-size: 26px; } }
  @media (max-width: 767px) {
    h3,
    .h3 {
      font-size: 22px; } }
  @media (max-width: 640px) {
    h3,
    .h3 {
      font-size: 20px; } }

h4,
.h4 {
  font-size: 30px; }
  @media (max-width: 1199px) {
    h4,
    .h4 {
      font-size: 26px; } }
  @media (max-width: 1023px) {
    h4,
    .h4 {
      font-size: 24px; } }
  @media (max-width: 767px) {
    h4,
    .h4 {
      font-size: 22px; } }
  @media (max-width: 640px) {
    h4,
    .h4 {
      font-size: 20px; } }

h5,
.h5 {
  font-size: 22px; }
  @media (max-width: 1199px) {
    h5,
    .h5 {
      font-size: 20px; } }
  @media (max-width: 1023px) {
    h5,
    .h5 {
      font-size: 18px; } }
  @media (max-width: 640px) {
    h5,
    .h5 {
      font-size: 14px; } }

h6,
.h6 {
  font-size: 20px; }
  @media (max-width: 1199px) {
    h6,
    .h6 {
      font-size: 18px; } }
  @media (max-width: 1023px) {
    h6,
    .h6 {
      font-size: 16px; } }
  @media (max-width: 640px) {
    h6,
    .h6 {
      font-size: 13px; } }

p {
  margin-bottom: 30px; }
  p a {
    color: inherit;
    text-decoration: underline; }
    p a:hover {
      color: #004D9B; }
  p:last-child {
    margin-bottom: 0; }

ul {
  padding-left: 20px;
  margin-bottom: 30px; }
  ul ul {
    margin-bottom: 0; }
  ul li {
    padding-top: 6px;
    padding-bottom: 6px; }
    ul li:last-child {
      padding-bottom: 0; }

ol {
  padding-left: 20px;
  margin-bottom: 30px; }
  ol ol {
    margin-bottom: 0; }
  ol li {
    padding-top: 6px;
    padding-bottom: 6px; }
    ol li:last-child {
      padding-bottom: 0; }

blockquote {
  position: relative;
  display: block;
  margin-bottom: 30px;
  padding: 20px 20px;
  text-align: left;
  line-height: 2;
  font-size: 20px;
  font-style: italic;
  color: #fff;
  background-color: #004D9B;
  border-left: 10px solid #FFD400; }
  @media (max-width: 1023px) {
    blockquote {
      font-size: 18px; } }
  @media (max-width: 767px) {
    blockquote {
      font-size: 16px; } }
  blockquote p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit; }

hr {
  height: 0;
  margin: 10px auto 40px;
  border: 0 #000 solid;
  border-top-width: 1px; }

img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: top;
  border: none; }

/* Link
------------------------------------------------------------------------------*/
a {
  color: #004D9B;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s; }
  a:hover, a:focus {
    color: #000;
    text-decoration: underline;
    outline: none; }

/* Tables
------------------------------------------------------------------------------*/
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff; }
  table th {
    background-color: #004D9B;
    color: #fff;
    border-color: #004D9B; }
  table td,
  table th {
    padding: 15px;
    font-size: 20px;
    white-space: nowrap;
    font-weight: 500;
    vertical-align: middle;
    text-align: center; }
    @media (max-width: 1023px) {
      table td,
      table th {
        font-size: 18px; } }
    @media (max-width: 767px) {
      table td,
      table th {
        font-size: 16px; } }
  table td {
    border: 1px solid #DEDEDE; }
  table tr:first-child {
    background-color: #ebebeb; }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 1px; }

.table-bordered {
  width: 100%;
  max-width: 100%;
  text-align: left; }

.custome-arrows {
  margin-left: -15px; }
  .custome-arrows .slick-arrow {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 1px solid #80AEDC;
    text-align: center;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    line-height: 50px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .custome-arrows .slick-arrow {
        width: 40px;
        height: 40px;
        line-height: 41px; } }
    .custome-arrows .slick-arrow:hover {
      background-color: #FFD400;
      color: #004D9B; }

.full-img {
  padding-top: 80px; }
  @media (max-width: 1023px) {
    .full-img {
      padding-top: 50px; } }
  @media (max-width: 767px) {
    .full-img {
      padding-top: 30px; } }
  .full-img img {
    width: 100%;
    display: block; }

.banner + .cms-content-block,
.hero-section + .cms-content-block {
  padding-top: 100px; }
  @media (max-width: 1199px) {
    .banner + .cms-content-block,
    .hero-section + .cms-content-block {
      padding-top: 80px; } }
  @media (max-width: 1023px) {
    .banner + .cms-content-block,
    .hero-section + .cms-content-block {
      padding-top: 60px; } }
  @media (max-width: 767px) {
    .banner + .cms-content-block,
    .hero-section + .cms-content-block {
      padding-top: 40px; } }

.cms-content-block {
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .cms-content-block {
      padding-bottom: 20px; } }
  .cms-content-block.full-content {
    padding-bottom: 130px; }
    @media (max-width: 1199px) {
      .cms-content-block.full-content {
        padding-bottom: 100px; } }
    @media (max-width: 1023px) {
      .cms-content-block.full-content {
        padding-bottom: 80px; } }
    @media (max-width: 767px) {
      .cms-content-block.full-content {
        padding-bottom: 60px; } }

/* buttons */
.btn, button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  display: inline-block;
  padding: 10px 15px;
  vertical-align: top;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  border: none;
  border-radius: 0px;
  background-color: #004D9B;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
  text-decoration: none;
  font-weight: 700; }
  @media (max-width: 767px) {
    .btn, button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      font-size: 12px; } }
  .btn:hover, button:hover,
  input:hover[type="button"],
  input:hover[type="reset"],
  input:hover[type="submit"], .btn:focus, button:focus,
  input:focus[type="button"],
  input:focus[type="reset"],
  input:focus[type="submit"] {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    background-color: #FFD400; }
  .btn-link {
    margin-bottom: 2px;
    color: #004D9B;
    font-size: 18px;
    border-bottom: 1px solid #004D9B;
    text-decoration: none;
    font-weight: 700; }
    @media (max-width: 767px) {
      .btn-link {
        font-size: 16px; } }
    .btn-link:hover, .btn-link:focus {
      color: #FFD400;
      text-decoration: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  padding: 14px 25px; }

label {
  display: block;
  margin: 10px 0;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  color: #000; }
  @media (max-width: 1023px) {
    label {
      font-size: 14px; } }

input,
textarea,
button,
select {
  font-family: "Gotham", sans-serif; }

input[type="text"],
input[type="submit"],
input[type="button"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  outline: none; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
textarea {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #D8D8D8; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #B4B4B4;
    opacity: 1 !important;
    font-style: normal; }
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="tel"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #B4B4B4;
    opacity: 1 !important;
    font-style: normal; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #B4B4B4;
    opacity: 1 !important;
    font-style: normal; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #B4B4B4;
    opacity: 1 !important;
    font-style: normal; }
  @media (max-width: 1023px) {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    textarea {
      font-size: 14px; } }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  textarea:focus {
    border-color: #004D9B;
    outline: none; }

textarea {
  height: 150px;
  padding: 20px 15px;
  resize: none;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

.form-group {
  margin-bottom: 20px; }
  @media (max-width: 1023px) {
    .form-group {
      margin-bottom: 15px; } }

input + label {
  font-size: 14px; }
  @media (max-width: 1023px) {
    input + label {
      font-size: 13px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*--- header ---*/
.main-header {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99; }
  @media (max-width: 1199px) {
    .main-header .container {
      padding: 0; } }
  @media (max-width: 1023px) {
    .main-header {
      background-color: #004D9B; } }
  .main-header.innner-header {
    background-color: #004D9B;
    position: relative; }
  .main-header .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .main-header .left-header {
    width: 200px; }
    @media (max-width: 1199px) {
      .main-header .left-header {
        width: 150px; } }
    @media (max-width: 767px) {
      .main-header .left-header {
        width: 100px; } }
    .main-header .left-header .logo {
      display: inline-block;
      vertical-align: top;
      width: 100%; }
      .main-header .left-header .logo img {
        width: 100%; }
  .main-header .right-header {
    width: calc(100% - 200px); }
    @media (max-width: 1199px) {
      .main-header .right-header {
        width: calc(100% - 150px); } }
    @media (max-width: 1023px) {
      .main-header .right-header {
        display: none; } }
    .main-header .right-header .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 20px;
      background: #002d5a;
      background: -moz-linear-gradient(left, #002d5a 1%, #004385 100%);
      background: -webkit-linear-gradient(left, #002d5a 1%, #004385 100%);
      background: linear-gradient(to right, #002d5a 1%, #004385 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002d5a', endColorstr='#004385', GradientType=1);
      position: relative; }
      @media (max-width: 1499px) {
        .main-header .right-header .top-header {
          padding: 5px 10px; } }
      @media (max-width: 1199px) {
        .main-header .right-header .top-header {
          padding: 3px 10px; } }
      .main-header .right-header .top-header .side-color {
        position: absolute;
        left: 100%;
        background-color: #004385;
        content: "";
        top: 0;
        width: 100vw;
        height: 100%; }
      .main-header .right-header .top-header .search-block {
        max-width: 700px;
        width: 100%;
        padding-left: 10px; }
        @media (max-width: 1499px) {
          .main-header .right-header .top-header .search-block {
            padding-left: 0;
            max-width: 500px; } }
        .main-header .right-header .top-header .search-block form {
          display: flex;
          justify-content: flex-start;
          align-items: center; }
          .main-header .right-header .top-header .search-block form input[type="submit"] {
            width: 50px;
            height: 50px;
            background-image: url("../images/search.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
            background-color: transparent; }
          .main-header .right-header .top-header .search-block form input[type="text"] {
            background-color: transparent;
            border: 0;
            color: #fff;
            font-style: normal;
            padding: 0 5px;
            font-size: 16px; }
            .main-header .right-header .top-header .search-block form input[type="text"]::-webkit-input-placeholder {
              /* WebKit browsers */
              color: #fff;
              opacity: 0.9 !important;
              font-style: normal; }
            .main-header .right-header .top-header .search-block form input[type="text"]:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #fff;
              opacity: 0.9 !important;
              font-style: normal; }
            .main-header .right-header .top-header .search-block form input[type="text"]::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #fff;
              opacity: 0.9 !important;
              font-style: normal; }
            .main-header .right-header .top-header .search-block form input[type="text"]:-ms-input-placeholder {
              /* Internet Explorer 10+ */
              color: #fff;
              opacity: 0.9 !important;
              font-style: normal; }
      .main-header .right-header .top-header .header-right-logo {
        margin-right: 30px; }
        .main-header .right-header .top-header .header-right-logo img {
          max-width: 250px; }
      .main-header .right-header .top-header .contact-link {
        display: flex;
        flex: 1 0 auto;
        justify-content: flex-end; }
        .main-header .right-header .top-header .contact-link a {
          color: #fff;
          outline: none;
          text-decoration: none;
          border: 0;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 13px; }
          .main-header .right-header .top-header .contact-link a:hover {
            color: #FFD400; }
          .main-header .right-header .top-header .contact-link a i {
            display: inline-block;
            vertical-align: middle;
            margin-left: 30px;
            color: #FFD400;
            line-height: 1; }
            @media (max-width: 1499px) {
              .main-header .right-header .top-header .contact-link a i {
                margin-left: 20px; } }
            @media (max-width: 1023px) {
              .main-header .right-header .top-header .contact-link a i {
                margin-left: 10px; } }
    .main-header .right-header .flag-img {
      margin-left: 40px; }
      .main-header .right-header .flag-img img {
        max-width: 40px;
        display: inline-block;
        width: 100%; }
    .main-header .right-header .navigation {
      padding: 0 20px 0 40px;
      background: #004D9B; }
      @media (max-width: 1499px) {
        .main-header .right-header .navigation {
          padding: 0 10px 0 25px; } }
      @media (max-width: 1199px) {
        .main-header .right-header .navigation {
          padding: 0 5px 0 15px; } }
      .main-header .right-header .navigation .side-color {
        position: absolute;
        left: 100%;
        background-color: #004D9B;
        content: "";
        top: 0;
        width: 100vw;
        height: 100%; }
      .main-header .right-header .navigation nav {
        width: 100%;
        height: auto; }
        .main-header .right-header .navigation nav ul {
          padding: 0;
          margin: 0 -5px;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center; }
          @media (max-width: 1199px) {
            .main-header .right-header .navigation nav ul {
              margin: 0; } }
          .main-header .right-header .navigation nav ul li {
            padding: 0 5px;
            margin: 0;
            list-style: none;
            display: block; }
            .main-header .right-header .navigation nav ul li:hover > a, .main-header .right-header .navigation nav ul li.current_page_item > a {
              color: #FFD400; }
            .main-header .right-header .navigation nav ul li.megamenu > a {
              position: relative; }
              .main-header .right-header .navigation nav ul li.megamenu > a:before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 17.5px 14px 17.5px;
                border-color: transparent transparent #fff transparent;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                -webkit-transition: all 0.4s ease 0s;
                transition: all 0.4s ease 0s; }
                @media (max-width: 1023px) {
                  .main-header .right-header .navigation nav ul li.megamenu > a:before {
                    border-width: 0 12.5px 11px 12.5px; } }
            .main-header .right-header .navigation nav ul li.megamenu:hover > a:before, .main-header .right-header .navigation nav ul li.megamenu.active > a:before {
              opacity: 1;
              visibility: visible;
              pointer-events: all; }
            .main-header .right-header .navigation nav ul li.megamenu:hover .mega-menu-wrapper, .main-header .right-header .navigation nav ul li.megamenu.active .mega-menu-wrapper {
              opacity: 1;
              visibility: visible;
              pointer-events: all; }
            .main-header .right-header .navigation nav ul li.sub-menu {
              position: relative; }
              .main-header .right-header .navigation nav ul li.sub-menu:hover > ul {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                top: 100%; }
              .main-header .right-header .navigation nav ul li.sub-menu ul {
                margin: 0;
                position: absolute;
                left: 0;
                top: 100px;
                flex-direction: column;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                background-color: #FFD400;
                min-width: 100%;
                -webkit-transition: all 0.4s ease 0s;
                transition: all 0.4s ease 0s;
                z-index: 2; }
                .main-header .right-header .navigation nav ul li.sub-menu ul li {
                  padding: 0;
                  display: block;
                  width: 100%; }
                  .main-header .right-header .navigation nav ul li.sub-menu ul li:hover > a {
                    color: #FFD400;
                    background-color: #004D9B; }
                  .main-header .right-header .navigation nav ul li.sub-menu ul li:hover ul {
                    top: 0; }
                  .main-header .right-header .navigation nav ul li.sub-menu ul li a {
                    color: #004D9B;
                    padding: 10px 15px;
                    text-align: left;
                    display: block;
                    width: 100%;
                    text-transform: capitalize; }
                  .main-header .right-header .navigation nav ul li.sub-menu ul li ul {
                    left: 100%;
                    top: 30px; }
            .main-header .right-header .navigation nav ul li:not(.sub-menu) > ul {
              display: none; }
            .main-header .right-header .navigation nav ul li .mega-menu-wrapper {
              position: absolute;
              left: 0;
              top: 100%;
              width: 100vw;
              height: auto;
              right: 0;
              background: #fff;
              opacity: 0;
              visibility: hidden;
              pointer-events: none;
              -webkit-transition: all 0.4s ease 0s;
              transition: all 0.4s ease 0s;
              z-index: 1; }
              .main-header .right-header .navigation nav ul li .mega-menu-wrapper:before, .main-header .right-header .navigation nav ul li .mega-menu-wrapper:after {
                position: absolute;
                right: 100%;
                top: 0;
                width: 100vw;
                content: "";
                background-color: #fff;
                height: 100%; }
              .main-header .right-header .navigation nav ul li .mega-menu-wrapper:after {
                right: auto;
                left: 100%; }
              .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul {
                display: flex;
                justify-content: center;
                align-items: stretch;
                flex-wrap: wrap; }
                .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li {
                  padding: 0;
                  width: 11.11%; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li:last-child > a:after {
                    display: none; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li:hover .menu-wrapper {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                    z-index: 1; }
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li:hover .menu-wrapper ul {
                      pointer-events: all; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li > a {
                    padding: 40px 20px;
                    text-align: center;
                    font-size: 14px;
                    position: relative;
                    display: block; }
                    @media (max-width: 1023px) {
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li > a {
                        padding: 25px 15px; } }
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul > li > a:after {
                      content: "";
                      position: absolute;
                      right: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                      height: 70%;
                      width: 1px;
                      background-color: #E2E2E2; }
                .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li a {
                  color: #222222;
                  font-weight: 500;
                  text-transform: capitalize;
                  white-space: pre-wrap; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li a .menu-cat-img {
                    height: 85px;
                    max-width: 85px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto; }
                    @media (max-width: 1023px) {
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li a .menu-cat-img {
                        height: 60px; } }
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li a .menu-cat-img img {
                      max-height: 100%; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li a img {
                    display: block;
                    margin: 0 auto 15px; }
                .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper {
                  width: 100%;
                  background-color: #fff;
                  position: absolute;
                  left: 0;
                  top: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: stretch;
                  font-size: 0;
                  padding: 40px 0;
                  border-top: 1px solid #E2E2E2;
                  margin: 0 auto;
                  opacity: 0;
                  visibility: hidden;
                  pointer-events: none;
                  -webkit-transition: all 0.4s ease 0s;
                  transition: all 0.4s ease 0s;
                  z-index: -1; }
                  @media (max-width: 1023px) {
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper {
                      padding: 25px 0; } }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper:before, .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper:after {
                    position: absolute;
                    right: 100%;
                    top: -1px;
                    width: 100vw;
                    content: "";
                    background-color: #fff;
                    height: 100%;
                    border-top: 1px solid #E2E2E2; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper:after {
                    right: auto;
                    left: 100%; }
                  .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list {
                    width: 33.33%;
                    padding: 0 50px;
                    display: inline-block;
                    border-right: 1px solid #E2E2E2;
                    margin: 0; }
                    @media (max-width: 1023px) {
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list {
                        padding: 0 30px; } }
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list:last-child {
                      border: 0; }
                    .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li {
                      width: 100%; }
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li:hover > a {
                        color: #004D9B; }
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li a {
                        font-size: 15px;
                        padding: 15px 0;
                        font-weight: 500; }
                        @media (max-width: 1023px) {
                          .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li a {
                            font-size: 15px;
                            padding: 10px 0; } }
                      .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul {
                        opacity: 1;
                        visibility: visible;
                        padding: 0;
                        width: 100%;
                        position: static;
                        background-color: transparent; }
                        .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul li {
                          padding-left: 30px;
                          position: relative; }
                          .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul li:hover a {
                            background-color: transparent; }
                        .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul a {
                          display: inline-block;
                          padding: 10px 0;
                          width: auto;
                          font-weight: 400; }
                          .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul a:before {
                            font-family: "crp" , sans-serif !important;
                            font-style: normal !important;
                            font-variant: normal !important;
                            font-weight: normal !important;
                            text-transform: none !important;
                            content: "f";
                            left: 0;
                            top: 10px;
                            position: absolute;
                            font-size: 16px;
                            color: #E2E2E2;
                            line-height: 1;
                            -webkit-transition: all 0.4s ease 0s;
                            transition: all 0.4s ease 0s; }
                            @media (max-width: 1023px) {
                              .main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper .submenu-product-list li ul a:before {
                                font-size: 14px; } }
            .main-header .right-header .navigation nav ul li a {
              padding: 35px 0;
              display: inline-block;
              text-decoration: none;
              line-height: 1;
              color: #fff;
              font-weight: 700;
              white-space: nowrap;
              font-size: 15px;
              text-transform: uppercase; }
              @media (max-width: 1499px) {
                .main-header .right-header .navigation nav ul li a {
                  font-size: 13px; } }
              @media (max-width: 1199px) {
                .main-header .right-header .navigation nav ul li a {
                  padding: 20px 0;
                  font-size: 11px; } }
              .main-header .right-header .navigation nav ul li a a {
                text-transform: capitalize; }

.m-flag-img {
  display: none; }
  @media (max-width: 767px) {
    .m-flag-img {
      display: block;
      position: absolute;
      right: 70px;
      top: 17px;
      width: 153px;
      max-width: 153px; } }

/* responsive nav for small devices */
.mobilenav {
  display: none; }
  @media (max-width: 1199px) {
    .mobilenav {
      display: block; } }
  .mobilenav .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #003468;
    padding: 0 15px; }
    .mobilenav .mobile-header .nav-logo {
      width: 150px;
      display: inline-block; }
      @media (max-width: 767px) {
        .mobilenav .mobile-header .nav-logo {
          width: 100px; } }
  .hamburger {
    display: flex;
    height: 30px;
    width: 35px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none; }
    @media (max-width: 1023px) {
      .hamburger {
        display: block; } }
    .hamburger:not(.close) {
      position: absolute;
      top: 37px;
      right: 20px;
      z-index: 100; }
      @media (max-width: 767px) {
        .hamburger:not(.close) {
          top: 23px; } }
    .hamburger.close .wrap {
      position: relative;
      display: block;
      width: 35px;
      height: 30px; }
      .hamburger.close .wrap .line {
        background-color: #FFD400; }
    .hamburger.close .line {
      position: absolute;
      top: calc(50% - 2px);
      width: 100%;
      margin: 0;
      transform: rotate(45deg);
      background-color: #fff; }
      .hamburger.close .line:last-child {
        transform: rotate(-45deg); }
    .hamburger:hover, .hamburger:focus {
      background-color: transparent;
      outline: none;
      cursor: pointer; }
    .hamburger .line {
      display: block;
      width: 100%;
      margin-bottom: 7px;
      height: 4px;
      background-color: #FFD400; }
      .hamburger .line:last-child {
        margin-bottom: 0; }
    .hamburger .wrap {
      display: block; }
  .mobilenav .menu-state {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: 999;
    width: 100%;
    max-width: 100%;
    background-color: #004D9B;
    transition: all 0.3s ease-in-out; }
  .mobilenav.is-open .menu-state {
    left: 0; }
  .mobilenav ul {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none; }
    .mobilenav ul ul {
      display: none; }
    .mobilenav ul li {
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none; }
      .mobilenav ul li .caret {
        position: absolute;
        top: 5px;
        right: 10px;
        display: block;
        width: 36px;
        height: 36px;
        cursor: pointer; }
        .mobilenav ul li .caret:not(.trigger-caret) {
          pointer-events: none; }
        .mobilenav ul li .caret:after, .mobilenav ul li .caret:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: block;
          margin: auto;
          width: 18px;
          height: 2px;
          background: #fff; }
        .mobilenav ul li .caret:after {
          transform: rotate(-90deg);
          transition: all .2s ease; }
      .mobilenav ul li.is-open > .caret:after {
        transform: rotate(0); }
      .mobilenav ul li a {
        z-index: 2;
        display: block;
        padding: 10px 15px;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        font-size: 18px;
        text-decoration: none;
        color: #fff; }
      .mobilenav ul li.has-sub > a {
        padding-right: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.3; }
      .mobilenav ul li li a {
        padding-left: 30px;
        font-size: 16px;
        background-color: #005ab5; }
      .mobilenav ul li li li a {
        padding-left: 50px;
        font-size: 15px;
        background-color: #0066ce; }
      .mobilenav ul li li li li a {
        padding-left: 60px;
        font-size: 14px;
        background-color: #0073e8; }
  .mobilenav .nav-backdrop {
    position: fixed;
    z-index: -1;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in;
    background: rgba(0, 0, 0, 0.6); }
  .mobilenav.is-open .nav-backdrop {
    opacity: 1;
    z-index: 999;
    visibility: visible; }
  .mobilenav .menu-outer {
    max-height: calc(100vh - 80px);
    padding-bottom: 60px;
    overflow-y: auto; }
    .mobilenav .menu-outer .mobile-menu-icon .mobile-menu-cat-img {
      width: 50px;
      height: 50px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-right: 10px; }
      .mobilenav .menu-outer .mobile-menu-icon .mobile-menu-cat-img img {
        max-height: 100%;
        max-width: 100%;
        margin-right: 15px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto; }
      .mobilenav .menu-outer .mobile-menu-icon .mobile-menu-cat-img.no-img img {
        filter: grayscale(100%) brightness(100); }
    .mobilenav .menu-outer .mobile-menu-icon > .caret {
      top: 15px; }

.menu-active {
  position: fixed;
  overflow: hidden; }

/*--- footer ---*/
.main-footer {
  width: 100%;
  height: auto;
  background: #004D9B;
  line-height: normal; }
  .main-footer .footer-wrapper .footer-block {
    padding: 50px 15px; }
    @media (max-width: 1023px) {
      .main-footer .footer-wrapper .footer-block {
        padding: 25px 15px; } }
    @media (max-width: 767px) {
      .main-footer .footer-wrapper .footer-block {
        padding: 0 15px;
        margin-bottom: 25px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .main-footer .footer-wrapper .footer-block.large {
        order: 2; } }
    @media (max-width: 767px) {
      .main-footer .footer-wrapper .footer-block:last-child {
        margin-bottom: 0; } }
    .main-footer .footer-wrapper .footer-block .footer-logo {
      margin-top: -50px;
      display: block;
      margin-bottom: 30px; }
      @media (max-width: 1199px) {
        .main-footer .footer-wrapper .footer-block .footer-logo {
          width: 160px;
          margin-bottom: 20px; } }
      @media (max-width: 1023px) {
        .main-footer .footer-wrapper .footer-block .footer-logo {
          margin-top: -25px; } }
      @media (max-width: 767px) {
        .main-footer .footer-wrapper .footer-block .footer-logo {
          margin-top: 0; } }
    .main-footer .footer-wrapper .footer-block address {
      font-style: normal;
      color: #fff;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 1.5; }
      @media (max-width: 1499px) {
        .main-footer .footer-wrapper .footer-block address {
          font-size: 16px; } }
      @media (max-width: 767px) {
        .main-footer .footer-wrapper .footer-block address {
          margin-bottom: 15px; } }
    .main-footer .footer-wrapper .footer-block * {
      color: #fff; }
    .main-footer .footer-wrapper .footer-block p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 10px; }
      @media (max-width: 1499px) {
        .main-footer .footer-wrapper .footer-block p {
          font-size: 16px; } }
      @media (max-width: 1023px) {
        .main-footer .footer-wrapper .footer-block p {
          font-size: 14px; } }
      @media (max-width: 767px) {
        .main-footer .footer-wrapper .footer-block p {
          margin-bottom: 5px; } }
    .main-footer .footer-wrapper .footer-block ul {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0; }
      @media (max-width: 640px) {
        .main-footer .footer-wrapper .footer-block ul {
          width: 100%;
          margin-bottom: 6px; } }
      .main-footer .footer-wrapper .footer-block ul li {
        list-style: none; }
        .main-footer .footer-wrapper .footer-block ul li a {
          text-decoration: none;
          font-size: 18px;
          line-height: 1.4; }
          @media (max-width: 1499px) {
            .main-footer .footer-wrapper .footer-block ul li a {
              font-size: 16px; } }
          .main-footer .footer-wrapper .footer-block ul li a:hover {
            color: #FFD400; }
    .main-footer .footer-wrapper .footer-block .h6 {
      font-size: 18px; }
      @media (max-width: 767px) {
        .main-footer .footer-wrapper .footer-block .h6 {
          margin-bottom: 10px;
          font-size: 16px; } }
    .main-footer .footer-wrapper .footer-block .news-block {
      margin-bottom: 25px;
      padding-bottom: 25px;
      position: relative;
      display: block;
      text-decoration: none; }
      @media (max-width: 767px) {
        .main-footer .footer-wrapper .footer-block .news-block {
          margin-bottom: 15px;
          padding-bottom: 15px; } }
      .main-footer .footer-wrapper .footer-block .news-block:hover p {
        color: #FFD400; }
      .main-footer .footer-wrapper .footer-block .news-block:last-child:before {
        display: none; }
      .main-footer .footer-wrapper .footer-block .news-block:before {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 90%;
        background-color: #98B1C9;
        content: ""; }
      .main-footer .footer-wrapper .footer-block .news-block p {
        font-weight: 700;
        font-size: 18px;
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s; }
        @media (max-width: 1199px) {
          .main-footer .footer-wrapper .footer-block .news-block p {
            font-size: 16px; } }
      .main-footer .footer-wrapper .footer-block .news-block .date {
        color: #98B1C9;
        font-style: italic; }
        @media (max-width: 1199px) {
          .main-footer .footer-wrapper .footer-block .news-block .date {
            font-size: 16px; } }
        @media (max-width: 767px) {
          .main-footer .footer-wrapper .footer-block .news-block .date {
            font-size: 14px; } }

.featured-section {
  background-color: #004D9B;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  position: relative;
  padding: 70px 0 60px; }
  @media (max-width: 1023px) {
    .featured-section {
      padding: 50px 0; } }
  @media (max-width: 767px) {
    .featured-section {
      background-size: cover; } }
  .featured-section:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 77, 155, 0.5);
    content: ""; }
    @media (max-width: 767px) {
      .featured-section:before {
        background-color: rgba(0, 77, 155, 0.8); } }
  .featured-section .featured-outer {
    position: relative;
    padding-left: 260px; }
    @media (max-width: 1499px) {
      .featured-section .featured-outer {
        padding-left: 150px; } }
    @media (max-width: 1023px) {
      .featured-section .featured-outer {
        padding-left: 0; } }
    .featured-section .featured-outer .featured-heading {
      margin-bottom: 15px; }
      .featured-section .featured-outer .featured-heading h3 {
        color: #fff;
        font-size: 35px;
        margin-bottom: 0; }
        @media (max-width: 1023px) {
          .featured-section .featured-outer .featured-heading h3 {
            font-size: 26px; } }
        @media (max-width: 767px) {
          .featured-section .featured-outer .featured-heading h3 {
            font-size: 20px; } }
      .featured-section .featured-outer .featured-heading p {
        font-size: 18px;
        text-transform: uppercase;
        color: #FFD400; }
        @media (max-width: 1023px) {
          .featured-section .featured-outer .featured-heading p {
            font-size: 16px; } }
    .featured-section .featured-outer .featured-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 -45px; }
      @media (max-width: 1499px) {
        .featured-section .featured-outer .featured-wrapper {
          margin: 0 -15px; } }
      @media (max-width: 1199px) {
        .featured-section .featured-outer .featured-wrapper {
          margin: 0 -10px; } }
      @media (max-width: 1023px) {
        .featured-section .featured-outer .featured-wrapper {
          margin: 0; } }
      .featured-section .featured-outer .featured-wrapper .featured-block {
        padding: 30px 45px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
        @media (max-width: 1499px) {
          .featured-section .featured-outer .featured-wrapper .featured-block {
            padding: 25px; } }
        @media (max-width: 1199px) {
          .featured-section .featured-outer .featured-wrapper .featured-block {
            padding: 20px 10px; } }
        .featured-section .featured-outer .featured-wrapper .featured-block .featured-img {
          border-radius: 50%;
          overflow: hidden;
          width: 73px;
          height: 73px;
          display: inline-block;
          background-color: #FFD400;
          position: relative; }
          @media (max-width: 1499px) {
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-img {
              width: 70px;
              height: 70px; } }
          @media (max-width: 1199px) {
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-img {
              width: 60px;
              height: 60px; } }
          .featured-section .featured-outer .featured-wrapper .featured-block .featured-img img {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            margin: 0 auto;
            width: 35px;
            height: 35px; }
            @media (max-width: 1199px) {
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-img img {
                width: 30px;
                height: 30px; } }
          .featured-section .featured-outer .featured-wrapper .featured-block .featured-img i {
            width: 100%;
            height: 100%;
            display: block;
            background-color: #FFD400;
            color: #004D9B;
            text-align: center;
            font-size: 36px;
            position: relative; }
            @media (max-width: 1023px) {
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-img i {
                font-size: 28px; } }
            @media (max-width: 767px) {
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-img i {
                font-size: 28px; } }
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-img i:before {
              position: absolute;
              left: 0;
              right: 0;
              top: 50%;
              transform: translate(0, -50%); }
        .featured-section .featured-outer .featured-wrapper .featured-block .featured-content {
          width: calc(100% - 93px);
          margin-top: 25px; }
          @media (max-width: 1499px) {
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-content {
              margin-top: 20px; } }
          @media (max-width: 1199px) {
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-content {
              width: calc(100% - 70px); } }
          @media (max-width: 1023px) {
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-content {
              margin-top: 15px; } }
          .featured-section .featured-outer .featured-wrapper .featured-block .featured-content .h5 {
            color: #fff;
            text-decoration: none;
            display: block;
            margin-bottom: 10px; }
            @media (max-width: 1499px) {
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-content .h5 {
                font-size: 20px; } }
            @media (max-width: 1199px) {
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-content .h5 {
                font-size: 18px; } }
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-content .h5:hover {
              color: #FFD400; }
          .featured-section .featured-outer .featured-wrapper .featured-block .featured-content ul {
            margin: 0;
            padding: 0; }
            .featured-section .featured-outer .featured-wrapper .featured-block .featured-content ul li {
              list-style: none;
              padding: 0; }
              .featured-section .featured-outer .featured-wrapper .featured-block .featured-content ul li a {
                color: #fff;
                font-size: 18px;
                text-decoration: none; }
                .featured-section .featured-outer .featured-wrapper .featured-block .featured-content ul li a:hover {
                  text-decoration: underline; }
                @media (max-width: 1199px) {
                  .featured-section .featured-outer .featured-wrapper .featured-block .featured-content ul li a {
                    font-size: 16px; } }

.map-distribution {
  position: relative;
  padding: 90px 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  @media (max-width: 1499px) {
    .map-distribution {
      padding: 50px 0; } }
  .map-distribution.has-image .map-content {
    width: 34%; }
    @media (max-width: 1023px) {
      .map-distribution.has-image .map-content {
        width: 50%; } }
    @media (max-width: 767px) {
      .map-distribution.has-image .map-content {
        width: 100%; } }
  .map-distribution .map-content .map-inner-content {
    padding: 0 15px; }
  .map-distribution .map-content h3 {
    font-size: 30px;
    color: #004D9B;
    margin-bottom: 40px; }
    @media (max-width: 1499px) {
      .map-distribution .map-content h3 {
        margin-bottom: 30px; } }
    @media (max-width: 1199px) {
      .map-distribution .map-content h3 {
        margin-bottom: 20px;
        font-size: 24px; } }
    @media (max-width: 767px) {
      .map-distribution .map-content h3 {
        font-size: 22px; } }
  @media (max-width: 1023px) {
    .map-distribution .map-content p {
      font-size: 16px; } }
  .map-distribution .map-content ul {
    margin: 80px 0 0 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap; }
    @media (max-width: 1499px) {
      .map-distribution .map-content ul {
        margin-top: 30px; } }
    .map-distribution .map-content ul li {
      list-style: none;
      width: 50%;
      padding: 10px 0;
      margin: 0;
      line-height: 1; }
      @media (max-width: 1199px) {
        .map-distribution .map-content ul li {
          padding: 7px 0; } }
      .map-distribution .map-content ul li a {
        display: inline-block;
        text-decoration: none;
        line-height: 1.2;
        font-size: 20px;
        font-weight: 700; }
        @media (max-width: 1199px) {
          .map-distribution .map-content ul li a {
            font-size: 16px; } }
        .map-distribution .map-content ul li a:hover {
          color: #FFD400; }
  .map-distribution .map-content .distributor-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between; }
    .map-distribution .map-content .distributor-list .distributor {
      width: 50%; }
      @media (min-width: 1024px) {
        .map-distribution .map-content .distributor-list .distributor {
          margin-bottom: 80px; }
          .map-distribution .map-content .distributor-list .distributor:nth-of-type(2n) {
            padding-left: 40px; }
          .map-distribution .map-content .distributor-list .distributor:nth-of-type(2n+1) {
            padding-right: 40px; } }
      @media (max-width: 1023px) {
        .map-distribution .map-content .distributor-list .distributor {
          margin-bottom: 50px; }
          .map-distribution .map-content .distributor-list .distributor:nth-of-type(2n) {
            padding-left: 20px; }
          .map-distribution .map-content .distributor-list .distributor:nth-of-type(2n+1) {
            padding-right: 20px; } }
      @media (max-width: 767px) {
        .map-distribution .map-content .distributor-list .distributor {
          width: 100%; } }
  .map-distribution .map-image {
    order: 1;
    width: 60%; }
    @media (max-width: 1023px) {
      .map-distribution .map-image {
        width: 50%; } }
    @media (max-width: 767px) {
      .map-distribution .map-image {
        width: 100%;
        margin-bottom: 20px;
        order: 0; } }
    .map-distribution .map-image img {
      width: 100%; }

.blue-container {
  background-color: #004D9B; }
  .blue-container * {
    color: #fff !important; }

.blog-section {
  padding: 70px 0; }
  @media (max-width: 1199px) {
    .blog-section {
      padding: 60px 0; } }
  @media (max-width: 1023px) {
    .blog-section {
      padding: 30px 0; } }
  .blog-section .blog-item {
    margin-bottom: 30px; }
    .blog-section .blog-item .blog-content {
      padding: 30px;
      background-color: #fff; }
      @media (max-width: 1499px) {
        .blog-section .blog-item .blog-content {
          padding: 20px; } }
      @media (min-width: 1024px) and (max-width: 1199px) {
        .blog-section .blog-item .blog-content {
          padding: 20px 15px; } }
      @media (max-width: 640px) {
        .blog-section .blog-item .blog-content {
          padding: 20px 15px; } }
      .blog-section .blog-item .blog-content span {
        display: block;
        margin-bottom: 30px;
        font-size: 11px;
        color: #A5A5A5;
        text-transform: uppercase; }
        @media (max-width: 1499px) {
          .blog-section .blog-item .blog-content span {
            margin-bottom: 15px; } }
        @media (min-width: 1024px) and (max-width: 1199px) {
          .blog-section .blog-item .blog-content span {
            margin-bottom: 15px; } }
        @media (max-width: 767px) {
          .blog-section .blog-item .blog-content span {
            margin-bottom: 15px; } }
      .blog-section .blog-item .blog-content .h6 {
        font-size: 18px;
        text-decoration: none;
        display: block;
        line-height: 1.5; }
        @media (max-width: 1499px) {
          .blog-section .blog-item .blog-content .h6 {
            margin-bottom: 20px; } }
        @media (min-width: 1024px) and (max-width: 1199px) {
          .blog-section .blog-item .blog-content .h6 {
            font-size: 13px;
            margin-bottom: 15px; } }
        @media (max-width: 767px) {
          .blog-section .blog-item .blog-content .h6 {
            margin-bottom: 15px; } }
        @media (max-width: 640px) {
          .blog-section .blog-item .blog-content .h6 {
            font-size: 15px; } }
        .blog-section .blog-item .blog-content .h6:hover {
          color: #FFD400; }
      .blog-section .blog-item .blog-content p {
        font-size: 14px; }
        @media (min-width: 1024px) and (max-width: 1199px) {
          .blog-section .blog-item .blog-content p {
            font-size: 13px; } }
    .blog-section .blog-item .blog-img {
      overflow: hidden; }
      .blog-section .blog-item .blog-img:hover img {
        transform: scale(1.05); }
      .blog-section .blog-item .blog-img img {
        width: 100%;
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s; }

.blue-bg {
  background-color: #E7EFF6;
  position: relative; }
  .blue-bg:before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../images/bg-overlay.jpg);
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.02;
    position: absolute; }

.gray-bg {
  background-color: #F5F5F5; }

.gradint-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00387c+0,e6eef5+100 */
  background: #00387c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #00387c 0%, #e6eef5 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00387c 0%, #e6eef5 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00387c 0%, #e6eef5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00387c', endColorstr='#e6eef5',GradientType=0 );
  /* IE6-9 */ }

.product-listing-section .product-listing-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1960px; }
  .product-listing-section .product-listing-wrapper.footer-image {
    margin-top: 20px;
    z-index: 5;
    position: relative; }
    .product-listing-section .product-listing-wrapper.footer-image img {
      width: 100%; }
  @media (max-width: 767px) {
    .product-listing-section .product-listing-wrapper {
      padding: 15px; } }
  @media (max-width: 640px) {
    .product-listing-section .product-listing-wrapper {
      padding: 10px 5px; } }
  .product-listing-section .product-listing-wrapper .product-block-outer {
    padding: 40px 15px; }
    @media (max-width: 1199px) {
      .product-listing-section .product-listing-wrapper .product-block-outer {
        padding: 30px 15px; } }
    @media (max-width: 767px) {
      .product-listing-section .product-listing-wrapper .product-block-outer {
        padding: 15px; } }
    @media (max-width: 640px) {
      .product-listing-section .product-listing-wrapper .product-block-outer {
        padding: 10px; } }
    .product-listing-section .product-listing-wrapper .product-block-outer .product-block {
      display: block;
      text-decoration: none;
      padding: 30px;
      background-color: #fff;
      height: 100%;
      -webkit-box-shadow: 0 3px 6px transparent;
      box-shadow: 0 3px 6px transparent;
      -webkit-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s; }
      @media (max-width: 767px) {
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block {
          padding: 15px; } }
      @media (max-width: 1023px) {
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block {
          padding: 20px; } }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block.gray-bg {
        background-color: #F5F5F5; }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block.gray-bg:hover {
          background-color: #fff; }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block.border-top {
        border-top: 1px solid #DEDEDE; }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block.border-top:hover {
          border-color: transparent; }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block:hover {
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block:hover h5 {
          color: #FFD400; }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block:hover .product-img img {
          transform: scale(1.05); }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block h5 {
        color: #004D9B;
        text-transform: capitalize;
        font-weight: 700;
        transition: 0.4s all;
        margin-bottom: 10px;
        font-size: 22px; }
        @media (max-width: 1199px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block h5 {
            font-size: 20px; } }
        @media (max-width: 1023px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block h5 {
            font-size: 18px; } }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block p {
        color: #717171;
        font-size: 17px;
        font-weight: 300; }
        @media (max-width: 1199px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block p {
            font-size: 15px;
            line-height: 1.2; } }
      .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img {
        width: 217px;
        height: 217px;
        margin: 0 auto;
        overflow: hidden; }
        @media (max-width: 1499px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img {
            width: 200px;
            height: 200px; } }
        @media (max-width: 1199px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img {
            width: 175px;
            height: 175px; } }
        @media (max-width: 767px) {
          .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img {
            width: 160px;
            height: 160px; } }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img.large-img {
          width: 100%;
          max-width: 500px;
          height: 250px;
          display: block;
          margin: 0 auto; }
          @media (max-width: 1499px) {
            .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img.large-img {
              height: 200px;
              max-width: 450px; } }
          @media (max-width: 1199px) {
            .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img.large-img {
              max-width: 400px;
              height: 200px; } }
          @media (max-width: 1023px) {
            .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img.large-img {
              max-width: 400px;
              height: 200px; } }
          @media (max-width: 640px) {
            .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img.large-img {
              max-width: 100%;
              height: 180px; } }
        .product-listing-section .product-listing-wrapper .product-block-outer .product-block .product-img img {
          transition: 0.4s all;
          display: block;
          margin: 0 auto;
          max-height: 100%;
          height: 100%; }

.product-category-section {
  padding: 60px 0; }
  @media (max-width: 1023px) {
    .product-category-section {
      padding: 40px 0; } }
  .product-category-section .product-category-block {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 40px;
    -webkit-box-shadow: 0 3px 6px transparent;
    box-shadow: 0 3px 6px transparent;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
    @media (max-width: 1199px) {
      .product-category-section .product-category-block {
        margin-bottom: 30px; } }
    @media (max-width: 767px) {
      .product-category-section .product-category-block {
        flex-wrap: wrap; } }
    .product-category-section .product-category-block:hover {
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .product-category-section .product-category-block .product-img {
      width: 50%;
      padding: 70px 100px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 1499px) {
        .product-category-section .product-category-block .product-img {
          padding: 50px; } }
      @media (max-width: 1199px) {
        .product-category-section .product-category-block .product-img {
          padding: 40px 10px; } }
      @media (max-width: 767px) {
        .product-category-section .product-category-block .product-img {
          width: 100%;
          padding: 10px;
          margin-bottom: 20px; } }
      .product-category-section .product-category-block .product-img:hover img {
        transform: scale(1.05); }
      .product-category-section .product-category-block .product-img img {
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        height: 250px; }
        @media (max-width: 1499px) {
          .product-category-section .product-category-block .product-img img {
            height: 200px; } }
        @media (max-width: 1023px) {
          .product-category-section .product-category-block .product-img img {
            height: 150px; } }
    .product-category-section .product-category-block .product-category-content {
      width: 50%;
      padding: 70px 100px;
      position: relative; }
      @media (max-width: 1499px) {
        .product-category-section .product-category-block .product-category-content {
          padding: 50px 50px 80px 50px; } }
      @media (max-width: 1199px) {
        .product-category-section .product-category-block .product-category-content {
          padding: 40px 15px 70px 10px; } }
      @media (max-width: 767px) {
        .product-category-section .product-category-block .product-category-content {
          width: 100%;
          padding: 0 15px; } }
      .product-category-section .product-category-block .product-category-content h5 {
        font-size: 26px; }
        @media (max-width: 1199px) {
          .product-category-section .product-category-block .product-category-content h5 {
            font-size: 20px; } }
        @media (max-width: 1023px) {
          .product-category-section .product-category-block .product-category-content h5 {
            font-size: 18px; } }
      .product-category-section .product-category-block .product-category-content p {
        font-size: 16px; }
      .product-category-section .product-category-block .product-category-content .btn, .product-category-section .product-category-block .product-category-content button,
      .product-category-section .product-category-block .product-category-content input[type="button"],
      .product-category-section .product-category-block .product-category-content input[type="reset"],
      .product-category-section .product-category-block .product-category-content input[type="submit"] {
        position: absolute;
        bottom: 0;
        padding: 25px 25px 25px 70px;
        line-height: 1;
        font-size: 14px;
        text-transform: capitalize; }
        @media (max-width: 1199px) {
          .product-category-section .product-category-block .product-category-content .btn, .product-category-section .product-category-block .product-category-content button,
          .product-category-section .product-category-block .product-category-content input[type="button"],
          .product-category-section .product-category-block .product-category-content input[type="reset"],
          .product-category-section .product-category-block .product-category-content input[type="submit"] {
            padding: 20px 20px 20px 40px; } }
        @media (max-width: 767px) {
          .product-category-section .product-category-block .product-category-content .btn, .product-category-section .product-category-block .product-category-content button,
          .product-category-section .product-category-block .product-category-content input[type="button"],
          .product-category-section .product-category-block .product-category-content input[type="reset"],
          .product-category-section .product-category-block .product-category-content input[type="submit"] {
            position: static;
            margin-top: 10px; } }
        .product-category-section .product-category-block .product-category-content .btn i, .product-category-section .product-category-block .product-category-content button i, .product-category-section .product-category-block .product-category-content input[type="button"] i, .product-category-section .product-category-block .product-category-content input[type="reset"] i, .product-category-section .product-category-block .product-category-content input[type="submit"] i {
          margin-left: 40px;
          display: inline-block;
          vertical-align: middle;
          font-size: 16px; }
          @media (max-width: 1199px) {
            .product-category-section .product-category-block .product-category-content .btn i, .product-category-section .product-category-block .product-category-content button i, .product-category-section .product-category-block .product-category-content input[type="button"] i, .product-category-section .product-category-block .product-category-content input[type="reset"] i, .product-category-section .product-category-block .product-category-content input[type="submit"] i {
              margin-left: 20px; } }
        .product-category-section .product-category-block .product-category-content .btn:hover, .product-category-section .product-category-block .product-category-content button:hover,
        .product-category-section .product-category-block .product-category-content input:hover[type="button"],
        .product-category-section .product-category-block .product-category-content input:hover[type="reset"],
        .product-category-section .product-category-block .product-category-content input:hover[type="submit"] {
          color: #000; }

.product-detail-section {
  padding: 70px 0; }
  @media (max-width: 1199px) {
    .product-detail-section {
      padding: 60px 0; } }
  @media (max-width: 1023px) {
    .product-detail-section {
      padding: 40px 0; } }
  .product-detail-section .product-information {
    text-align: center; }
    .product-detail-section .product-information .h3 {
      color: #004D9B;
      font-size: 34px; }
      @media (max-width: 1199px) {
        .product-detail-section .product-information .h3 {
          font-size: 30px; } }
      @media (max-width: 1023px) {
        .product-detail-section .product-information .h3 {
          font-size: 26px; } }
      @media (max-width: 767px) {
        .product-detail-section .product-information .h3 {
          font-size: 23px; } }
      @media (max-width: 640px) {
        .product-detail-section .product-information .h3 {
          font-size: 21px; } }
    .product-detail-section .product-information .product-images {
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 1023px) {
        .product-detail-section .product-information .product-images {
          flex-wrap: wrap;
          margin-bottom: 20px; } }
  .product-detail-section .product-info-detail p {
    font-size: 16px; }
  @media (max-width: 767px) {
    .product-detail-section .product-info-detail .product-table {
      overflow-x: scroll; } }
  .product-detail-section .product-info-detail .product-table table {
    margin: 50px 0; }
    @media (max-width: 1023px) {
      .product-detail-section .product-info-detail .product-table table {
        margin: 30px 0; } }

.product-table-wrap {
  overflow-x: auto;
  width: 100%;
  border: 1px dashed #004d9b; }

.hero-section {
  position: relative;
  padding-top: 145px; }
  @media (max-width: 1199px) {
    .hero-section {
      padding-top: 110px; } }
  @media (max-width: 767px) {
    .hero-section {
      padding-top: 70px; } }
  .hero-section .hero-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed; }
  .hero-section .hero-content {
    position: relative; }
    .hero-section .hero-content:before, .hero-section .hero-content:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      background-image: url(../images/transparent-skew.png);
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
      width: 400px;
      height: 400px; }
      @media (max-width: 1499px) {
        .hero-section .hero-content:before, .hero-section .hero-content:after {
          width: 350px;
          height: 350px; } }
      @media (max-width: 1199px) {
        .hero-section .hero-content:before, .hero-section .hero-content:after {
          width: 270px;
          height: 270px; } }
    .hero-section .hero-content:after {
      top: auto;
      bottom: 0; }
    .hero-section .hero-content .custome-arrows {
      margin: 0;
      position: absolute;
      display: inline-block;
      z-index: 9;
      transition: 0.4s all; }
      @media (max-width: 767px) {
        .hero-section .hero-content .custome-arrows {
          bottom: 25px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0 !important;
          right: 0 !important; } }
    .hero-section .hero-content .hero-slider {
      z-index: 9; }
      .hero-section .hero-content .hero-slider .hero-wrapper {
        position: relative;
        height: 605px;
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 1499px) {
          .hero-section .hero-content .hero-slider .hero-wrapper {
            height: 500px; } }
        @media (max-width: 1199px) {
          .hero-section .hero-content .hero-slider .hero-wrapper {
            height: 400px; } }
        @media (max-width: 767px) {
          .hero-section .hero-content .hero-slider .hero-wrapper {
            height: auto;
            padding: 30px 0 70px;
            flex-wrap: wrap; } }
      .hero-section .hero-content .hero-slider .hero-detail {
        width: 50%;
        padding-left: calc((100% - 1050px)/2); }
        @media (max-width: 1199px) {
          .hero-section .hero-content .hero-slider .hero-detail {
            padding-left: 50px; } }
        @media (max-width: 1023px) {
          .hero-section .hero-content .hero-slider .hero-detail {
            padding-left: 15px; } }
        @media (max-width: 767px) {
          .hero-section .hero-content .hero-slider .hero-detail {
            width: 100%;
            align-items: center;
            margin-bottom: 30px;
            padding: 0 15px; } }
        .hero-section .hero-content .hero-slider .hero-detail .hero-detial-inner {
          padding-bottom: 80px; }
          @media (max-width: 767px) {
            .hero-section .hero-content .hero-slider .hero-detail .hero-detial-inner {
              padding-bottom: 0;
              text-align: center; } }
        .hero-section .hero-content .hero-slider .hero-detail h1, .hero-section .hero-content .hero-slider .hero-detail .h1 {
          color: #fff;
          margin-bottom: 0; }
        .hero-section .hero-content .hero-slider .hero-detail p {
          color: #fff; }
          @media (max-width: 1023px) {
            .hero-section .hero-content .hero-slider .hero-detail p {
              font-size: 16px; } }
      .hero-section .hero-content .hero-slider .hero-image {
        width: 50%;
        order: 1;
        padding: 0 15px; }
        @media (max-width: 767px) {
          .hero-section .hero-content .hero-slider .hero-image {
            width: 100%;
            padding: 0;
            transform: none;
            position: static;
            padding: 15px;
            order: 0; } }
        .hero-section .hero-content .hero-slider .hero-image img {
          margin: 0 auto;
          max-width: 75%;
          width: 100%; }
          @media (max-width: 767px) {
            .hero-section .hero-content .hero-slider .hero-image img {
              max-width: 320px; } }
          @media (max-width: 640px) {
            .hero-section .hero-content .hero-slider .hero-image img {
              max-width: 250px; } }

.inner-banner {
  position: relative;
  border-bottom: 20px solid #FFD400; }
  .inner-banner .inner-banner-img {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.15; }
    .inner-banner .inner-banner-img:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      content: ""; }
  .inner-banner .inner-hero-wrapper {
    padding: 130px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media (max-width: 1499px) {
      .inner-banner .inner-hero-wrapper {
        padding: 100px 0; } }
    @media (max-width: 1199px) {
      .inner-banner .inner-hero-wrapper {
        padding: 60px 0; } }
    @media (max-width: 1023px) {
      .inner-banner .inner-hero-wrapper {
        padding: 40px 0; } }
    @media (max-width: 767px) {
      .inner-banner .inner-hero-wrapper {
        flex-wrap: wrap; } }
    .inner-banner .inner-hero-wrapper .inner-hero-img {
      width: 45%;
      padding: 0 80px;
      text-align: center; }
      @media (max-width: 1199px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img {
          padding: 0 40px; } }
      @media (max-width: 1023px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img {
          padding: 0 15px;
          width: 40%; } }
      @media (max-width: 767px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img {
          width: 100%;
          padding: 0;
          margin-bottom: 20px; } }
      @media (max-width: 767px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img img {
          max-width: 320px; } }
      @media (max-width: 640px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img img {
          max-width: 250px; } }
    .inner-banner .inner-hero-wrapper .inner-hero-img-wrapper {
      width: 45%;
      padding: 0 80px;
      text-align: center; }
      @media (max-width: 1023px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img-wrapper {
          width: 40%; } }
      @media (max-width: 767px) {
        .inner-banner .inner-hero-wrapper .inner-hero-img-wrapper {
          width: 100%; } }
      .inner-banner .inner-hero-wrapper .inner-hero-img-wrapper .inner-hero-img {
        width: 100%;
        padding: 0;
        margin-bottom: 40px; }
        .inner-banner .inner-hero-wrapper .inner-hero-img-wrapper .inner-hero-img:last-of-type {
          margin-bottom: 0; }
    .inner-banner .inner-hero-wrapper .inner-hero-content {
      width: 55%; }
      @media (max-width: 1023px) {
        .inner-banner .inner-hero-wrapper .inner-hero-content {
          width: 60%; } }
      @media (max-width: 767px) {
        .inner-banner .inner-hero-wrapper .inner-hero-content {
          width: 100%; } }
      .inner-banner .inner-hero-wrapper .inner-hero-content .h4 {
        margin-bottom: 30px;
        color: #004D9B; }
        @media (max-width: 1199px) {
          .inner-banner .inner-hero-wrapper .inner-hero-content .h4 {
            margin-bottom: 25px; } }
      .inner-banner .inner-hero-wrapper .inner-hero-content p {
        margin-bottom: 30px;
        font-size: 16px; }
        @media (max-width: 1199px) {
          .inner-banner .inner-hero-wrapper .inner-hero-content p {
            margin-bottom: 25px; } }
        .inner-banner .inner-hero-wrapper .inner-hero-content p:last-child {
          margin-bottom: 0; }
      .inner-banner .inner-hero-wrapper .inner-hero-content table tr th, .inner-banner .inner-hero-wrapper .inner-hero-content table tr td {
        white-space: pre-wrap;
        font-size: 15px;
        padding: 15px 5px; }

.banner {
  height: 35vh;
  max-height: 500px;
  min-height: 300px;
  overflow: hidden;
  position: relative; }
  @media (max-width: 767px) {
    .banner {
      height: 20vh;
      min-height: 200px; } }
  .banner .banner-bg {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; }
    .banner .banner-bg.dark-bg:before {
      background-color: rgba(0, 54, 132, 0.7); }
    .banner .banner-bg:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(17, 35, 60, 0.5);
      content: ""; }
  .banner .banner-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .banner .banner-content .banner-content-inner {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center; }
      .banner .banner-content .banner-content-inner:before, .banner .banner-content .banner-content-inner:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        background-image: url(../images/transparent-skew.png);
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        width: 400px;
        height: 400px; }
        @media (max-width: 1499px) {
          .banner .banner-content .banner-content-inner:before, .banner .banner-content .banner-content-inner:after {
            width: 350px;
            height: 350px; } }
        @media (max-width: 1199px) {
          .banner .banner-content .banner-content-inner:before, .banner .banner-content .banner-content-inner:after {
            width: 270px;
            height: 270px; } }
      .banner .banner-content .banner-content-inner:after {
        top: auto;
        bottom: 0; }
      .banner .banner-content .banner-content-inner .container {
        position: relative;
        z-index: 9; }
    .banner .banner-content h1 {
      color: #FFD400; }
    .banner .banner-content p {
      margin-bottom: 0;
      color: #C4DEF7;
      font-size: 18px; }
      @media (max-width: 640px) {
        .banner .banner-content p {
          font-size: 16px; } }
      .banner .banner-content p a:hover {
        color: #FFD400; }
    .banner .banner-content .find-location {
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      display: inline-block;
      margin-top: 90px; }
      @media (max-width: 1499px) {
        .banner .banner-content .find-location {
          margin-top: 60px; } }
      @media (max-width: 1199px) {
        .banner .banner-content .find-location {
          margin-top: 40px; } }
      @media (max-width: 1023px) {
        .banner .banner-content .find-location {
          margin-top: 20px; } }
      @media (max-width: 640px) {
        .banner .banner-content .find-location {
          font-size: 14px; } }
      .banner .banner-content .find-location:hover {
        color: #FFD400; }
      .banner .banner-content .find-location i {
        color: #FF7676;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
        line-height: 1; }
        @media (max-width: 640px) {
          .banner .banner-content .find-location i {
            font-size: 20px; } }
      .banner .banner-content .find-location img {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle; }

.product-category {
  padding: 20px 0; }
  @media (max-width: 1023px) {
    .product-category {
      padding: 20px 50px; } }
  .product-category ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: stretch; }
    @media (max-width: 1199px) {
      .product-category ul {
        flex-wrap: wrap; } }
    .product-category ul li {
      list-style: none;
      padding: 0 40px;
      margin: 0;
      border-right: 1px solid #DEDEDE; }
      @media (max-width: 1499px) {
        .product-category ul li {
          padding: 0 30px; } }
      @media (max-width: 1199px) {
        .product-category ul li {
          padding: 0 15px;
          width: 25%; } }
      @media (max-width: 1023px) {
        .product-category ul li {
          padding: 0 7px;
          width: 33.33%; } }
      @media (max-width: 767px) {
        .product-category ul li {
          margin: 10px 0;
          width: 50%; } }
      @media (max-width: 640px) {
        .product-category ul li {
          width: 100%; } }
      .product-category ul li:last-child {
        border: 0; }
      .product-category ul li a {
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 15px;
        font-weight: 700;
        color: #717171;
        white-space: nowrap; }
        @media (max-width: 1199px) {
          .product-category ul li a {
            font-size: 13px; } }
        .product-category ul li a:hover {
          color: #004D9B; }
        .product-category ul li a img {
          display: block;
          margin: 0 auto;
          height: 85px; }

.product-category-slider:before {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #fff;
  content: "";
  z-index: 1; }

.product-category-slider .slick-arrow {
  background-color: transparent;
  font-size: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  outline: none; }
  .product-category-slider .slick-arrow:before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    font-family: "crp" , sans-serif !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    content: "f";
    font-size: 22px;
    color: #004D9B; }
  .product-category-slider .slick-arrow.slick-next {
    right: -50px; }
  .product-category-slider .slick-arrow.slick-prev {
    left: -50px; }
    .product-category-slider .slick-arrow.slick-prev:before {
      font-family: "crp" , sans-serif !important;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      text-transform: none !important;
      content: "d"; }

.page-info-wrapper {
  position: relative; }
  .page-info-wrapper .page-info {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0);
    background-color: #fff; }
    .page-info-wrapper .page-info.fixed {
      position: fixed;
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.2); }

.page-info {
  padding: 20px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s all; }
  @media (max-width: 1199px) {
    .page-info {
      padding: 20px 15px; } }
  @media (max-width: 1023px) {
    .page-info {
      flex-wrap: wrap; } }
  .page-info.detail-breadcum {
    justify-content: space-between; }
    .page-info.detail-breadcum .download-pdf {
      width: auto; }
      @media (max-width: 640px) {
        .page-info.detail-breadcum .download-pdf {
          width: 100%; } }
  .page-info .breadcrumbs {
    width: 33.33%;
    padding-left: 25px; }
    @media (max-width: 1023px) {
      .page-info .breadcrumbs {
        width: 100%;
        margin: 10px 0;
        padding-left: 15px; } }
    .page-info .breadcrumbs ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -15px;
      padding: 0;
      position: relative;
      padding-left: 35px; }
      .page-info .breadcrumbs ul li {
        padding: 0 15px 0 25px;
        list-style: none;
        position: relative; }
        @media (max-width: 1499px) {
          .page-info .breadcrumbs ul li {
            padding: 0 10px 0 20px; } }
        @media (max-width: 1199px) {
          .page-info .breadcrumbs ul li {
            padding: 0 7px 0 14px; } }
        .page-info .breadcrumbs ul li:first-child {
          padding-left: 0;
          position: absolute;
          top: 0;
          left: 0; }
        .page-info .breadcrumbs ul li:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          font-family: "crp" , sans-serif !important;
          font-style: normal !important;
          font-variant: normal !important;
          font-weight: normal !important;
          text-transform: none !important;
          content: "f";
          font-size: 13px;
          color: #A5A5A5;
          line-height: 1; }
          @media (max-width: 1199px) {
            .page-info .breadcrumbs ul li:before {
              left: -2px; } }
        .page-info .breadcrumbs ul li:first-child:before {
          display: none; }
        .page-info .breadcrumbs ul li a {
          text-decoration: none;
          color: #000;
          font-weight: 500;
          font-size: 16px; }
          @media (max-width: 1199px) {
            .page-info .breadcrumbs ul li a {
              font-size: 15px; } }
          .page-info .breadcrumbs ul li a:before {
            line-height: 1;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px; }
          .page-info .breadcrumbs ul li a:hover {
            color: #004D9B; }
          .page-info .breadcrumbs ul li a.active {
            pointer-events: none; }

.product-links {
  width: 33.33%; }
  @media (max-width: 1023px) {
    .product-links {
      width: 100%;
      margin: 10px 0; } }
  .product-links ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 1023px) {
      .product-links ul {
        justify-content: flex-start;
        margin: 0 -8px; } }
    @media (max-width: 640px) {
      .product-links ul {
        flex-wrap: wrap; } }
    .product-links ul li {
      list-style: none;
      padding: 0 15px; }
      @media (max-width: 1499px) {
        .product-links ul li {
          padding: 0 10px; } }
      @media (max-width: 1199px) {
        .product-links ul li {
          padding: 0 8px; } }
      @media (max-width: 640px) {
        .product-links ul li {
          display: block;
          width: 100%; } }
      .product-links ul li a {
        color: #004D9B;
        text-decoration: none;
        font-weight: 500;
        font-family: "HelveticaNeue", sans-serif;
        font-size: 16px; }
        @media (max-width: 1199px) {
          .product-links ul li a {
            font-size: 15px; } }
        .product-links ul li a:hover {
          color: #000; }

.download-pdf {
  width: 33.33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  color: #000;
  font-size: 14px;
  background: #fce013;
  padding: 10px; }
  @media (max-width: 1023px) {
    .download-pdf {
      justify-content: flex-start;
      margin-top: 10px; } }
  @media (max-width: 1023px) {
    .download-pdf {
      width: 100%; } }
  @media (max-width: 640px) {
    .download-pdf {
      margin: 10px 0; } }
  .download-pdf .pdf-icon {
    margin-right: 15px; }
    @media (max-width: 1199px) {
      .download-pdf .pdf-icon {
        margin-right: 10px; } }
    .download-pdf .pdf-icon img {
      height: 100%;
      max-height: 48px;
      max-width: 48px;
      width: auto; }
  .download-pdf:hover {
    text-decoration: none;
    color: #004D9B; }

.page-info-right {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 1023px) {
    .page-info-right {
      width: 100%;
      margin: 10px 0; } }
  @media (max-width: 640px) {
    .page-info-right {
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: -10px; } }
  .page-info-right .btn, .page-info-right button,
  .page-info-right input[type="button"],
  .page-info-right input[type="reset"],
  .page-info-right input[type="submit"] {
    margin-left: 50px;
    padding: 20px 25px 20px 40px;
    line-height: 1;
    font-size: 16px;
    font-weight: 700; }
    @media (max-width: 1499px) {
      .page-info-right .btn, .page-info-right button,
      .page-info-right input[type="button"],
      .page-info-right input[type="reset"],
      .page-info-right input[type="submit"] {
        margin-left: 40px; } }
    @media (max-width: 1199px) {
      .page-info-right .btn, .page-info-right button,
      .page-info-right input[type="button"],
      .page-info-right input[type="reset"],
      .page-info-right input[type="submit"] {
        margin-left: 25px;
        padding: 20px 20px 20px 30px; } }
    @media (max-width: 640px) {
      .page-info-right .btn, .page-info-right button,
      .page-info-right input[type="button"],
      .page-info-right input[type="reset"],
      .page-info-right input[type="submit"] {
        margin-left: 0;
        padding: 15px;
        font-size: 14px; } }
    .page-info-right .btn i, .page-info-right button i, .page-info-right input[type="button"] i, .page-info-right input[type="reset"] i, .page-info-right input[type="submit"] i {
      margin-left: 25px;
      display: inline-block;
      vertical-align: middle; }
      @media (max-width: 1199px) {
        .page-info-right .btn i, .page-info-right button i, .page-info-right input[type="button"] i, .page-info-right input[type="reset"] i, .page-info-right input[type="submit"] i {
          margin-left: 15px; } }
    .page-info-right .btn:hover, .page-info-right button:hover,
    .page-info-right input:hover[type="button"],
    .page-info-right input:hover[type="reset"],
    .page-info-right input:hover[type="submit"] {
      color: #000; }

.contact-section {
  padding: 80px 0 150px;
  position: relative; }
  @media (max-width: 1199px) {
    .contact-section {
      padding: 50px 0 100px; } }
  @media (max-width: 767px) {
    .contact-section {
      padding: 40px 0; } }
  .contact-section:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(255, 255, 255, 0.93); }
  .contact-section .contact-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative; }
    @media (max-width: 767px) {
      .contact-section .contact-wrapper {
        flex-wrap: wrap; } }
    .contact-section .contact-wrapper .contact-info {
      width: calc(40% - 40px);
      background-color: #fff;
      padding: 40px 50px;
      margin-right: 40px;
      border-top: 10px solid #FFD400;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16); }
      @media (max-width: 1199px) {
        .contact-section .contact-wrapper .contact-info {
          padding: 25px 30px; } }
      @media (max-width: 1023px) {
        .contact-section .contact-wrapper .contact-info {
          width: calc(35% - 40px);
          padding: 20px; } }
      @media (max-width: 767px) {
        .contact-section .contact-wrapper .contact-info {
          width: 100%;
          padding: 0 15px;
          margin-bottom: 40px;
          margin-right: 0; } }
      .contact-section .contact-wrapper .contact-info address {
        font-style: normal;
        margin-bottom: 50px;
        font-weight: 500; }
        @media (max-width: 1199px) {
          .contact-section .contact-wrapper .contact-info address {
            margin-bottom: 30px;
            font-size: 16px; } }
        @media (max-width: 1023px) {
          .contact-section .contact-wrapper .contact-info address {
            font-size: 15px; } }
      .contact-section .contact-wrapper .contact-info .contact-detail ul {
        padding: 0;
        margin: 0 0 50px 0; }
        @media (max-width: 1199px) {
          .contact-section .contact-wrapper .contact-info .contact-detail ul {
            margin-bottom: 30px; } }
        .contact-section .contact-wrapper .contact-info .contact-detail ul li {
          padding: 0;
          list-style: none;
          color: #000;
          margin-bottom: 20px;
          font-weight: 500; }
          @media (max-width: 1199px) {
            .contact-section .contact-wrapper .contact-info .contact-detail ul li {
              margin-bottom: 15px; } }
          .contact-section .contact-wrapper .contact-info .contact-detail ul li:last-child {
            margin-bottom: 0; }
          .contact-section .contact-wrapper .contact-info .contact-detail ul li a {
            margin-left: 5px;
            color: inherit;
            text-decoration: none;
            font-weight: inherit; }
            @media (max-width: 1199px) {
              .contact-section .contact-wrapper .contact-info .contact-detail ul li a {
                font-size: 16px; } }
            @media (max-width: 1023px) {
              .contact-section .contact-wrapper .contact-info .contact-detail ul li a {
                font-size: 15px; } }
            .contact-section .contact-wrapper .contact-info .contact-detail ul li a:hover {
              color: #004D9B;
              text-decoration: underline; }
      .contact-section .contact-wrapper .contact-info .registration-detail p {
        color: #000;
        margin-bottom: 0;
        font-weight: 500; }
        @media (max-width: 1199px) {
          .contact-section .contact-wrapper .contact-info .registration-detail p {
            font-size: 16px; } }
        @media (max-width: 1023px) {
          .contact-section .contact-wrapper .contact-info .registration-detail p {
            font-size: 15px; } }
      .contact-section .contact-wrapper .contact-info .extra-text {
        margin-top: 20px; }
    .contact-section .contact-wrapper .contact-form {
      width: 60%;
      background-color: #fff;
      padding: 40px 50px;
      border-top: 10px solid #FFD400;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16); }
      @media (max-width: 1199px) {
        .contact-section .contact-wrapper .contact-form {
          padding: 25px 30px; } }
      @media (max-width: 1023px) {
        .contact-section .contact-wrapper .contact-form {
          width: 63%;
          padding: 20px; } }
      @media (max-width: 767px) {
        .contact-section .contact-wrapper .contact-form {
          width: 100%; } }
      .contact-section .contact-wrapper .contact-form input[type="submit"] {
        padding: 20px 70px;
        background-image: url("../images/white-arrow-long.svg");
        background-size: 18px 15px;
        background-repeat: no-repeat;
        background-position: 90% 50%; }
        @media (max-width: 1199px) {
          .contact-section .contact-wrapper .contact-form input[type="submit"] {
            padding: 15px 60px; } }

.inner-address-block label {
  font-weight: 400; }

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  min-height: 100%;
  flex-wrap: wrap;
  background-color: #fff; }
  @media (min-width: 1024px) {
    .wrapper {
      overflow-x: hidden;
      overflow-y: auto; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .wrapper {
      overflow: visible; } }

.main-container {
  width: 100%; }

.search-content-block .search-items {
  padding-bottom: 5px;
  padding-top: 20px;
  border-top: 1px solid #E2E2E2;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  @media (max-width: 767px) {
    .search-content-block .search-items {
      flex-wrap: wrap; } }
  .search-content-block .search-items .search-img {
    width: 200px;
    padding: 0 15px; }
    @media (max-width: 767px) {
      .search-content-block .search-items .search-img {
        width: 100%;
        max-width: 200px;
        margin: 0 auto 20px; } }
  .search-content-block .search-items .search-content {
    width: calc(100% - 200px); }
    @media (max-width: 767px) {
      .search-content-block .search-items .search-content {
        width: 100%; } }
    .search-content-block .search-items .search-content .h6 {
      text-decoration: none;
      display: inline-block;
      margin-bottom: 15px; }
      .search-content-block .search-items .search-content .h6:hover {
        color: #FFD400; }
    .search-content-block .search-items .search-content p {
      margin-bottom: 15px; }

.pagination-wrap {
  margin: 50px 0; }
  .pagination-wrap .nav-pagination .page-numbers {
    line-height: 1;
    padding: 0 15px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500; }
    .pagination-wrap .nav-pagination .page-numbers.current, .pagination-wrap .nav-pagination .page-numbers:hover {
      color: #FFD400; }

span.wpcf7-not-valid-tip {
  font-size: 14px; }

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  font-size: 14px;
  text-align: center; }

div.wpcf7 .ajax-loader {
  margin-top: 20px; }
  @media (max-width: 1023px) {
    div.wpcf7 .ajax-loader {
      margin-top: 15px; } }
  @media (max-width: 767px) {
    div.wpcf7 .ajax-loader {
      margin-top: 15px; } }

.flag-contact-wrap {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 300px;
  width: 100%; }

.language-dropdown {
  position: absolute;
  top: -20px;
  left: -20px;
  background: #002d5a;
  max-width: 153px;
  width: 100%;
  height: 63px;
  z-index: 9; }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .language-dropdown {
      height: 60px; } }
  @media (max-width: 1023px) {
    .language-dropdown {
      height: 77px;
      left: 0; } }
  .language-dropdown:before {
    content: "";
    position: absolute;
    right: 22px;
    top: 25px;
    background: url(../images/down-arrow.png);
    width: 18px;
    height: 11px;
    background-size: 100% 100%;
    pointer-events: none; }
    @media (max-width: 1023px) {
      .language-dropdown:before {
        top: 33px; } }
  .language-dropdown.open:before {
    transform: rotate(-180deg); }
  .language-dropdown .country-name {
    color: #fff;
    font-size: 14px; }
  .language-dropdown img {
    width: 30px;
    border: 1px solid #fff;
    padding: 4px;
    height: 20px;
    object-fit: cover;
    margin-left: 10px; }
  .language-dropdown .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -15px; }
  .language-dropdown ul, .language-dropdown li {
    margin: 0;
    padding: 0; }
  .language-dropdown li {
    list-style: none;
    background: #484848;
    display: flex;
    align-items: center;
    justify-content: center; }
    .language-dropdown li a {
      width: 100%;
      text-align: center;
      text-decoration: none;
      padding: 15px 0; }
  .language-dropdown .select-list .selected {
    display: none; }
  .language-dropdown .dropdown-button {
    padding-top: 13%;
    padding-bottom: 13%; }
    @media (max-width: 1023px) {
      .language-dropdown .dropdown-button {
        padding-top: 18%; } }

.social-icons {
  margin-top: 30px;
  margin-bottom: 30px; }
  .social-icons img {
    height: 25px; }
  .social-icons a {
    margin-right: 30px; }
    .social-icons a:hover {
      opacity: 0.8; }

.footer-flag {
  display: flex;
  align-items: center; }
  .footer-flag img {
    border: 1px solid #fff;
    width: 47px;
    padding: 6px; }
  .footer-flag span {
    margin-left: 10px; }

.product-listing-section h3 {
  font-size: 22px;
  color: #000000;
  max-width: 1420px;
  margin: 0 auto 40px; }
  @media (max-width: 1500px) {
    .product-listing-section h3 {
      padding: 0 20px; } }

.footer-language-dropdown .language-dropdown {
  position: relative;
  top: auto;
  left: auto;
  height: auto; }

.footer-language-dropdown .select-list {
  width: 100% !important; }

/* Home product section redesign */
.inner-banner .inner-hero-wrapper {
  align-items: flex-start; }

.product-listing-section .product-listing-wrapper.row {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: stretch; }

.product-listing-section {
  position: relative;
  padding: 30px 0px;
  background-color: #fff; }

.product-listing-section:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/wp-content/themes/crp/assets/images/home-background.png");
  z-index: 0; }

.product-listing-section .product-block {
  height: 500px;
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 40px;
  transition: ease all 0.3s;
  margin-bottom: 15px; }
  @media (max-width: 1199px) {
    .product-listing-section .product-block {
      padding: 30px; } }

.product-listing-section .product-block:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(0, 105, 212, 0.3) 0%, rgba(0, 105, 212, 0) 100%);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  transition: ease all 0.3s;
  z-index: -1; }

.product-listing-section .product-block:hover {
  text-decoration: none; }

.product-listing-section .product-block:hover:before {
  opacity: 1; }

.product-listing-section .product-block:hover h5,
.product-listing-section .product-block:hover p {
  color: #004D9B; }

.product-listing-section .product-block:hover p {
  border: solid 2px #004D9B; }

.product-listing-section .product-block:hover .product-title-button p:after {
  background-image: url("/wp-content/themes/crp/assets/images/arrow-blue-dark.png"); }

.product-listing-section .product-block p {
  display: none; }

.product-listing-section .product-block h5 {
  margin-bottom: 0px;
  color: #0069D4;
  transition: ease all 0.3s; }

.product-listing-section .product-block .product-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 365px;
  padding-bottom: 20px; }

.product-listing-section .product-block .product-img img {
  width: 70%;
  height: 100%;
  object-fit: contain;
  transition: ease all 0.3s;
  font-family: 'object-fit: contain;'; }

.product-listing-section .product-block:hover img {
  transform: scale(1.1); }

.product-title-button {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.product-listing-section .product-title-button p {
  display: block;
  color: #0069D4;
  padding: 15px;
  padding-right: 55px;
  border: solid 2px #0069D4;
  font-weight: 700;
  position: relative;
  transition: ease all 0.3s; }

.product-listing-section .product-title-button p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 15px;
  top: calc(50% - 7.5px);
  right: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/wp-content/themes/crp/assets/images/arrow-blue.png");
  transition: ease all 0.3s; }

@media (min-width: 1000px) {
  .product-listing-section .product-block {
    width: calc(33% - 5px); }
  .product-listing-section .product-block h5 {
    max-width: 50%; } }

.product-listing-section .product-block.contact {
  display: none; }

.product-listing-section .product-container:last-child .product-block.contact:last-child {
  display: flex; }

.product-listing-section .product-block.contact {
  justify-content: center; }

.product-listing-section .product-block.contact p {
  display: block;
  border: none;
  color: #000;
  font-size: 18px;
  margin-bottom: 15px; }

.product-listing-section .product-block.contact:hover p {
  border: none; }

.product-listing-section .product-block.contact h2 {
  font-size: 25px;
  margin-bottom: 15px;
  color: #0069D4; }

.product-listing-section .product-block.contact a {
  color: #000;
  margin-bottom: 15px;
  font-size: 18px;
  text-decoration: underline;
  transition: ease all 0.3s;
  padding-left: 40px;
  position: relative; }

.product-listing-section .product-block.contact a:before,
.product-listing-section .product-block.contact p.location:before {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 5px;
  left: 0px;
  transition: ease all 0.3s; }

.product-listing-section .product-block.contact p.location {
  padding-left: 40px;
  position: relative; }

.product-listing-section .product-block.contact a.phone:before {
  background-image: url("/wp-content/themes/crp/assets/images/phone-black.png"); }

.product-listing-section .product-block.contact a.email:before {
  background-image: url("/wp-content/themes/crp/assets/images/email-black.png"); }

.product-listing-section .product-block.contact p.location:before {
  background-image: url("/wp-content/themes/crp/assets/images/location-black.png"); }

.product-listing-section .product-block.contact a:hover {
  color: #004D9B; }

.product-listing-section .product-block.contact a.phone:hover:before {
  background-image: url("/wp-content/themes/crp/assets/images/phone-blue.png"); }

.product-listing-section .product-block.contact a.email:hover:before {
  background-image: url("/wp-content/themes/crp/assets/images/email-blue.png"); }

/* Header redesign */
.main-header {
  background-color: #004d9b; }

.main-header .right-header .navigation nav ul li .mega-menu-wrapper .menu-title > ul li .menu-wrapper {
  display: none; }

.main-header .right-header .navigation nav ul li a {
  position: relative; }

.main-header .right-header .navigation nav ul li a:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: #8BA0B5;
  opacity: 0.5;
  top: calc(50% - 25px);
  right: -20px; }
  @media (max-width: 1499px) {
    .main-header .right-header .navigation nav ul li a:after {
      right: calc(((100vw / 16.2 - 74.5px) + 12px) * -1); } }
  @media (max-width: 1199px) {
    .main-header .right-header .navigation nav ul li a:after {
      right: calc(((100vw / 17.2 - 59.5px) + 15px) * -1); } }

.main-header .right-header .navigation nav ul li.megamenu:last-child > a:after {
  display: none; }

.featured-section .featured-outer {
  padding-left: 0px; }

.banner.home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/wp-content/themes/crp/assets/images/background-home.png");
  height: auto;
  background-attachment: fixed;
  min-height: initial;
  max-height: initial; }

.banner.home.product-page .banner-content {
  padding-top: 0px; }

.banner.home h2,
.banner.home p {
  color: #fff;
  text-align: left; }

.banner.home p {
  opacity: 0.85; }

.banner.home .banner-content {
  position: static;
  overflow: hidden; }

.banner.home .banner-content .banner-content-inner:before,
.banner.home .banner-content .banner-content-inner:after {
  display: none; }

.banner.home .banner-content-inner {
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  max-width: 1420px;
  margin: 0 auto; }

.banner.home .banner-content-inner.text {
  width: 100%; }

.banner.home .banner-content-inner .text hr {
  display: none; }

.banner.home .banner-content-inner .image {
  width: 100%;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

@media (max-width: 1499px) {
  .banner.home .banner-content-inner {
    padding: 30px 20px; } }

@media (max-width: 1023px) {
  .banner.home .banner-content-inner .text {
    order: 2; }
  .banner.home .banner-content-inner .image {
    order: 1;
    height: 130px;
    margin-bottom: 20px; } }

@media (min-width: 1500px) {
  .banner.home .banner-content-inner {
    padding: 40px 0px; } }

@media (min-width: 1000px) {
  .banner.home .banner-content-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative; }
  .banner.home .banner-content-inner .text {
    width: 50%; }
  .banner.home .banner-content-inner .text h2,
  .banner.home .banner-content-inner .text h1 {
    font-size: 28px;
    margin-bottom: 20px; }
  .banner.home .banner-content-inner .text p {
    width: 70%; }
  .banner.home .banner-content-inner .image {
    display: block;
    width: 500px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 40px; }
  .banner.home .banner-content-inner .text hr {
    height: 2px;
    background-color: #fff;
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
    display: block; } }

.banner.home .banner-content-inner .image.product-page {
  top: 0px; }

/* Product page redesign */
.breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1420px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .breadcrumb-container {
      flex-direction: column; } }

.page-info .breadcrumbs {
  width: 100%; }

.page-info .breadcrumbs ul {
  width: 100%; }

.page-info-right {
  width: 100%;
  justify-content: flex-end; }

.page-info {
  border-bottom: solid 1px #ececec; }

.mega-menu-wrapper:before,
.mega-menu-wrapper:after {
  display: none; }

.single-products .product-detail-section h1 {
  text-align: center; }

.product-detail-section h1,
.product-detail-section p,
.product-detail-section ul {
  text-align: left;
  font-size: 17px;
  margin-bottom: 20px; }

.product-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center; }

.product-images img {
  width: 45%;
  height: auto;
  margin-bottom: 20px;
  min-height: 1px; }

.product-table {
  overflow-x: scroll;
  margin-bottom: 20px; }

.file-links {
  flex-shrink: 0;
  flex-grow: 2;
  display: flex;
  justify-content: flex-end; }
  .file-links .download-pdf {
    width: auto;
    margin-left: 10px;
    background: #fce013;
    padding: 10px; }
  @media (max-width: 767px) {
    .file-links {
      justify-content: flex-start;
      flex-direction: column;
      width: 100%; }
      .file-links .download-pdf {
        margin-left: 0; } }

table td,
table th {
  font-size: 15px;
  font-weight: 400; }

.product-information {
  z-index: 1; }

@media (min-width: 1000px) {
  .product-detail-section .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .product-detail-section .product-information {
    width: 55%; }
  .product-detail-section .product-images {
    width: 35%;
    flex-direction: column; }
  .product-detail-section .product-images img {
    width: 100%; } }

@media (min-width: 1200px) {
  .product-table {
    overflow-x: hidden; }
  .product-table table {
    width: 99%; } }

/* Header slider */
.owl-dots {
  width: 90vw;
  max-width: 1420px;
  margin: 0 auto;
  margin-bottom: 20px;
  z-index: 999; }

.owl-dots .owl-dot {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 360px;
  background-color: transparent;
  border: solid 2px #AAAAAA;
  padding: 0px;
  transition: ease all 0.3s;
  opacity: 0.5; }

.owl-dots .owl-dot:hover {
  opacity: 1; }

.owl-dots .owl-dot.active {
  background-color: #AAAAAA; }

.owl-dots .owl-dot:last-child {
  margin-right: 0px; }

.owl-carousel .owl-item {
  opacity: 0 !important; }
  .owl-carousel .owl-item.active {
    opacity: 1 !important; }

.menu-cat-img img {
  width: 100%; }

.banner.home .banner-content-inner .text {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.banner.home .banner-content-inner .text a {
  margin-top: 15px;
  color: #FFF;
  transition: ease all 0.3s;
  display: flex;
  text-decoration: none;
  border: solid 2px;
  padding: 10px 15px;
  width: auto;
  font-size: 16px; }

.banner.home .banner-content-inner .text a.download-pdf {
  width: auto;
  color: black !important; }

.banner.home .banner-content-inner .text a:hover {
  color: #FFD400; }

.inner-banner .inner-hero-wrapper {
  flex-direction: row-reverse; }

.inner-banner-img {
  display: none; }

ul li {
  font-size: 16px; }

.inner-banner .inner-hero-wrapper {
  padding: 45px 0px; }
