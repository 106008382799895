.blog-section{
	padding: 70px 0;
	@include respond-below(xl) {
		padding: 60px 0;
	}
	@include respond-below(lg) {
		padding: 30px 0;
	}
	.blog-item{
		margin-bottom: 30px;
		.blog-content{
			padding: 30px;
			background-color: $white;
            @include respond-below(xxl) {
                padding: 20px;
            }
			@include respond-between(lg, xl) {
				padding: 20px 15px;
			}
			@include respond-below(sm) {
				padding: 20px 15px;
			}
			span{
				display: block;
				margin-bottom: 30px;
				font-size: $font-size-small - 5;
				color: $breadcumb-arrow;
				text-transform: uppercase;
                @include respond-below(xxl) {
                    margin-bottom: 15px;
                }
				@include respond-between(lg, xl) {
					margin-bottom: 15px;
				}
				@include respond-below(md) {
					margin-bottom: 15px;
				}
			}
			.h6{
				font-size: $font-size-h6 - 2;
				text-decoration: none;
				display: block;
				line-height: 1.5;
                @include respond-below(xxl) {
                    margin-bottom: 20px;
                }
				@include respond-between(lg, xl) {
					font-size: $font-size-h6 - 7;
					margin-bottom: 15px;
				}
				@include respond-below(md) {
					margin-bottom: 15px;
				}
				@include respond-below(sm) {
					font-size: $font-size-h6 - 5;
				}
				&:hover{
					color: $brand-secondary;
				}
			}
			p{
				font-size: $font-size-small - 2;
				@include respond-between(lg, xl) {
					font-size: $font-size-small - 3;
				}
			}
		}
		.blog-img{
			overflow: hidden;
			&:hover{
				img{
					transform: scale(1.05);
				}
			}
			img{
				width: 100%;
				@include transition(0.4s);
			}
		}
	}
}