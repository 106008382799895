.blue-bg {
    background-color: $light-blue;
    position: relative;

    &:before {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url(../images/bg-overlay.jpg);
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.02;
        position: absolute;
    }
}

.gray-bg {
    background-color: $gray;
}
.gradint-bg{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00387c+0,e6eef5+100 */
    background: #00387c; /* Old browsers */
    background: -moz-linear-gradient(top, #00387c 0%, #e6eef5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00387c 0%,#e6eef5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00387c 0%,#e6eef5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00387c', endColorstr='#e6eef5',GradientType=0 ); /* IE6-9 */
}

.product-listing-section {
    .product-listing-wrapper {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 0 15px;
        margin: 0 auto;
        max-width: 1960px;

        &.footer-image {
            margin-top: 20px;
            z-index: 5;
            position: relative;

            img {
                width: 100%;
            }
        }

        @include respond-below(md) {
            padding: 15px;
        }

        @include respond-below(sm) {
            padding: 10px 5px;
        }

        .product-block-outer {
            padding: 40px 15px;

            @include respond-below(xl) {
                padding: 30px 15px;
            }

            @include respond-below(md) {
                padding: 15px;
            }

            @include respond-below(sm) {
                padding: 10px;
            }

            .product-block {
                display: block;
                text-decoration: none;
                padding: 30px;
                background-color: $white;
                height: 100%;
                @include box-shadow(0 3px 6px transparent);
                @include transition(0.4s);

                @include respond-below(md) {
                    padding: 15px;
                }

                @include respond-below(lg) {
                    padding: 20px;
                }

                &.gray-bg {
                    background-color: $gray;

                    &:hover {
                        background-color: $white;
                    }
                }

                &.border-top {
                    border-top: 1px solid $gray-dark;

                    &:hover {
                        border-color: transparent;
                    }
                }

                &:hover {
                    @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

                    h5 {
                        color: $brand-secondary;
                    }

                    .product-img {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }

                h5 {
                    color: $brand-primary;
                    text-transform: capitalize;
                    font-weight: 700;
                    transition: 0.4s all;
                    margin-bottom: 10px;
                    font-size: $font-size-h5;

                    @include respond-below(xl) {
                        font-size: $font-size-h5 - 2;
                    }

                    @include respond-below(lg) {
                        font-size: $font-size-h5 - 4;
                    }
                }

                p {
                    color: $gray-font;
                    font-size: $font-size-base - 1;
                    font-weight: 300;

                    @include respond-below(xl) {
                        font-size: $font-size-small - 1;
                        line-height: 1.2;
                    }
                }

                .product-img {
                    @include square(217px);
                    margin: 0 auto;
                    overflow: hidden;

                    @include respond-below(xxl) {
                        @include square(200px);
                    }

                    @include respond-below(xl) {
                        @include square(175px);
                    }

                    @include respond-below(md) {
                        @include square(160px);
                    }
                    &.large-img{
                        width: 100%;
                        max-width: 500px;
                        height: 250px;
                        display: block;
                        margin: 0 auto;
                        @include respond-below(xxl) {
                            height: 200px;
                            max-width: 450px;
                        }
                        @include respond-below(xl) {
                            max-width: 400px;
                            height: 200px;
                        }
                        @include respond-below(lg) {
                            max-width: 400px;
                            height: 200px;
                        }
                        @include respond-below(sm) {
                            max-width: 100%;
                            height: 180px;
                        }
                    }
                    img {
                        transition: 0.4s all;
                        display: block;
                        margin: 0 auto;
                        max-height: 100%;
                        height: 100%;
                        @include respond-below(xl) {
                            
                        }
                    }
                }
            }
        }
    }
}

.product-category-section {
    padding: 60px 0;

    @include respond-below(lg) {
        padding: 40px 0;
    }

    .product-category-block {
        background-color: $white;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 40px;
        @include box-shadow(0 3px 6px transparent);
        @include transition(0.4s);

        @include respond-below(xl) {
            margin-bottom: 30px;
        }

        @include respond-below(md) {
            flex-wrap: wrap;
        }

        &:hover {
            @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .product-img {
            width: 50%;
            padding: 70px 100px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond-below(xxl) {
                padding: 50px;
            }

            @include respond-below(xl) {
                padding: 40px 10px;
            }

            @include respond-below(md) {
                width: 100%;
                padding: 10px;
                margin-bottom: 20px;
            }

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }

            img {
                @include transition(0.4s);
                height: 250px;
                @include respond-below(xxl) {
                    height: 200px;
                }
                @include respond-below(lg) {
                    height: 150px;
                }
            }
        }

        .product-category-content {
            width: 50%;
            padding: 70px 100px;
            position: relative;

            @include respond-below(xxl) {
                padding: 50px 50px 80px 50px;
            }

            @include respond-below(xl) {
                padding: 40px 15px 70px 10px;
            }

            @include respond-below(md) {
                width: 100%;
                padding: 0 15px;
            }

            h5 {
                font-size: $font-size-h5 + 4;

                @include respond-below(xl) {
                    font-size: $font-size-h5 - 2;
                }

                @include respond-below(lg) {
                    font-size: $font-size-h5 - 4;
                }
            }

            p {
                font-size: $font-size-small;
            }

            .btn {
                position: absolute;
                bottom: 0;
                padding: 25px 25px 25px 70px;
                line-height: 1;
                font-size: $font-size-small - 2;
                text-transform: capitalize;

                @include respond-below(xl) {
                    padding: 20px 20px 20px 40px;
                }

                @include respond-below(md) {
                    position: static;
                    margin-top: 10px;
                }

                i {
                    margin-left: 40px;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: $font-size-small;

                    @include respond-below(xl) {
                        margin-left: 20px;
                    }
                }

                &:hover {
                    color: $text-color;
                }
            }
        }
    }
}

.product-detail-section {
    padding: 70px 0;

    @include respond-below(xl) {
        padding: 60px 0;
    }
    @include respond-below(lg) {
        padding: 40px 0;
    }
    .product-information {
        text-align: center;

        .h3 {
            color: $brand-primary;
            font-size: $font-size-h3 - 2;

            @include respond-below(xl) {
                font-size: $font-size-h3 - 6;
            }
            @include respond-below(lg) {
                font-size: $font-size-h3 - 10;
            }
            @include respond-below(md) {
                font-size: $font-size-h3 - 13;
            }
            @include respond-below(sm) {
                font-size: $font-size-h3 - 15;
            }
        }

        .product-images {
            display: flex;
            justify-content: center;
            align-items: center;
            @include respond-below(lg) {
                flex-wrap: wrap;
                margin-bottom: 20px;
            }

        }
    }

    .product-info-detail {
        p {
            font-size: $font-size-small;
        }

        .product-table {
            @include respond-below(md) {
                overflow-x: scroll;
            }
            table {
                margin: 50px 0;

                @include respond-below(lg) {
                    margin: 30px 0;
                }
            }
        }
    }
}

.product-table-wrap {
    overflow-x: auto;
    width: 100%;
    border: 1px dashed #004d9b;
}