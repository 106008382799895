// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/* buttons */
.btn {
	display: inline-block;
	padding: 10px 15px;
	vertical-align: top;
	color: $white;
	font-size: $font-size-small - 2;
	line-height: 1.5;
	text-align: center;
	border: none;
	border-radius: 0px;
	background-color: $brand-primary;
	transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
	text-decoration: none;
	font-weight: 700;

	@include respond-below(md) {
		font-size: $font-size-small - 4;
	}


	&:hover,
	&:focus {
		cursor: pointer;
		color: $white;
		text-decoration: none;
		background-color: $brand-secondary;
	}

	&-link {
		margin-bottom: 2px;
		color: $brand-primary;
		font-size: $font-size-base;
		border-bottom: 1px solid $brand-primary;
		text-decoration: none;
		font-weight: 700;

		@include respond-below(md) {
			font-size: $font-size-base - 2;
		}

		&:hover,
		&:focus {
			color: $brand-secondary;
			text-decoration: none;
		}
	}
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@extend .btn;
	-webkit-appearance: button;
    padding: 14px 25px;
}