.featured-section{
    background-color: $brand-primary;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    position: relative;
    padding: 70px 0 60px;
    @include respond-below(lg) {
        padding: 50px 0;
    }
    @include respond-below(md) {
        background-size: cover;
    }
    &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($brand-primary,0.5);
        content: "";
        @include respond-below(md) {
            background-color: rgba($brand-primary,0.8);
        }
    }
    .featured-outer{
        position: relative;
        padding-left: 260px;
        @include respond-below(xxl) {
            padding-left: 150px;
        }
        @include respond-below(lg) {
            padding-left: 0;
        }
        .featured-heading{
            margin-bottom: 15px;
            h3{
                color: $white;
                font-size: $font-size-h3 - 1;
                margin-bottom: 0;
                @include respond-below(lg) {
                    font-size: $font-size-h3 - 10;
                }
                @include respond-below(md) {
                    font-size: $font-size-h3 - 16;
                }
            }
            p{
                font-size: $font-size-base;
                text-transform: uppercase;
                color: $brand-secondary;
                @include respond-below(lg) {
                    font-size: $font-size-small;
                }
            }
        }
        .featured-wrapper{
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: 0 -45px;
            @include respond-below(xxl) {
                margin: 0 -15px;
            }
            @include respond-below(xl) {
                margin: 0 -10px;
            }
            @include respond-below(lg) {
                margin: 0;
            }
            .featured-block{
                padding: 30px 45px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @include respond-below(xxl) {
                    padding: 25px;
                }
                @include respond-below(xl) {
                    padding: 20px 10px;
                }
                .featured-img{
                    border-radius: 50%;
                    overflow: hidden;
                    @include square(73px);
                    display: inline-block;
                    background-color: $brand-secondary;
                    position: relative;
                    @include respond-below(xxl) {
                        @include square(70px);
                    }
                    @include respond-below(xl) {
                        @include square(60px);
                    }
                    img{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translate(0,-50%);
                        margin: 0 auto;
                        @include square(35px);
                         @include respond-below(xl) {
                        @include square(30px);
                    }
                    }
                    i{
                        @include square(100%);
                        display:block;
                        background-color: $brand-secondary;
                        color: $brand-primary;
                        text-align: center;
                        font-size: $font-size-base + 18;
                        position: relative;
                        @include respond-below(lg) {
                            font-size: $font-size-base + 10;
                        }
                        @include respond-below(md) {
                            font-size: $font-size-base + 10;
                        }
                        &:before{
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }
                }
                .featured-content{
                    width: calc(100% - 93px);
                    margin-top: 25px;
                    @include respond-below(xxl) {
                        margin-top: 20px;
                    }
                    @include respond-below(xl) {
                        width: calc(100% - 70px);
                    }
                    @include respond-below(lg) {
                        margin-top: 15px;
                    }
                    .h5{
                        color: $white;
                        text-decoration: none;
                        display: block;
                        margin-bottom: 10px;
                        @include respond-below(xxl) {
                            font-size: $font-size-h5 - 2;
                        }
                        @include respond-below(xl) {
                            font-size: $font-size-h5 - 4;
                        }
                        &:hover{
                            color: $brand-secondary;
                        }
                    }
                    ul{
                        margin: 0;
                        padding: 0;
                        li{
                            list-style: none;
                            padding: 0;
                            a{
                                color: $white;
                                font-size: $font-size-base;
                                text-decoration: none;
                                &:hover{
                                    text-decoration: underline;
                                }
                                @include respond-below(xl) {
                                    font-size: $font-size-small;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}